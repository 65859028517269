import firebase, { getSettings, saveSettings } from './firebase';
import i18next from './i18n';
import axios from 'axios';
import get from 'lodash/get';

export const ADD_SENTENCE = 'ADD_SENTENCE';
export const addSentence = (sentence) => {
  return (dispatch, getState) => {
    return dispatch({
      type: ADD_SENTENCE,
      sentence: {
        ...sentence,
        writingStyle: getState().settings.writingStyle,
      },
    });
  };
};

export const UPDATE_SENTENCE = 'UPDATE_SENTENCE';
export const updateSentence = (index, update) => ({
  type: UPDATE_SENTENCE,
  index: index,
  update: update,
});

export const REMOVE_SENTENCE = 'REMOVE_SENTENCE';
export const removeSentence = (index) => ({
  type: REMOVE_SENTENCE,
  index: index,
});

export const CLEAR_CACHE = 'CLEAR_CACHE';
export const clearCache = () => ({
  type: CLEAR_CACHE,
});

export const UPDATE_ALL_SENTENCES = 'UPDATE_ALL_SENTENCES';
export const updateAllSentences = () => ({
  type: UPDATE_ALL_SENTENCES,
});

export const SET_USER_INFO = 'SET_USER_INFO';
export const setUserInfo = ({ uid, email, providerData }) => ({
  type: SET_USER_INFO,
  uid,
  email,
  loginProviderId: providerData[0].providerId,
});

export const SHOW_SETTINGS_MODAL = 'SHOW_SETTINGS_MODAL';
export const showSettingsModal = (modalProps = {}) => ({
  type: SHOW_SETTINGS_MODAL,
  modalProps,
});

export const HIDE_SETTINGS_MODAL = 'HIDE_SETTINGS_MODAL';
export const hideSettingsModal = () => ({
  type: HIDE_SETTINGS_MODAL,
});

export const SHOW_ACCOUNT_MODAL = 'SHOW_ACCOUNT_MODAL';
export const showAccountModal = (modalProps = {}) => ({
  type: SHOW_ACCOUNT_MODAL,
  modalProps,
});

export const HIDE_ACCOUNT_MODAL = 'HIDE_ACCOUNT_MODAL';
export const hideAccountModal = () => ({
  type: HIDE_ACCOUNT_MODAL,
});

const onChangeInterfaceLanguage = (newLanguageCode) => {
  i18next.changeLanguage(newLanguageCode);

  const firebaseLocales = {
    en: 'en',
    'zh-Hans': 'zh-CN',
    'zh-Hant': 'zh-TW',
    fr: 'fr',
    ja: 'ja',
    de: 'de',
    ru: 'ru',
    uk: 'uk',
    es: 'es',
    pt: 'pt-BR',
  };

  firebase.auth().languageCode = firebaseLocales[newLanguageCode] || 'en';
};

export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const fetchSettings = () => {
  return (dispatch, getState) => {
    getSettings(getState().user.uid, (doc) => {
      let allSettings = doc.data() || {
        interfaceLanguage: 'en',
        highlightIssues: true,
      };

      // See if there's a language in the route first.
      if (i18next.language === 'en') {
        // If not, then use the current setting
        onChangeInterfaceLanguage(allSettings.interfaceLanguage);
      } else {
        // If there was a language in the route, use that instead
        allSettings.interfaceLanguage = i18next.language;
      }

      // Set an initial translation language value if it didn't exist
      // if (allSettings.interfaceLanguage !== 'en' && allSettings.translationLanguage === undefined) {
      //   allSettings.translationLanguage = allSettings.interfaceLanguage;
      // }

      // Load the settings we have so far to make sure the page is fast
      dispatch({
        type: LOAD_SETTINGS,
        allSettings,
      });

      if (!allSettings.proficiency) {
        dispatch(
          showSettingsModal({
            showCloseButton: false,
            showNextButton: true,
          })
        );
      }

      // Then try to load the subscription info
      if (allSettings.customerId) {
        const subscriptionUrl = `https://gcp.gramara.com/subscription?customerId=${allSettings.customerId}`;
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            axios
              .get(
                subscriptionUrl,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              )
              .then((resp) => {
                dispatch({
                  type: LOAD_SETTINGS,
                  allSettings: {
                    ...allSettings,
                    subscription: resp.data.subscription,
                  },
                });
                dispatch(fetchDailyWordsRemaining());
              })
              .catch((err) => {
                console.log(err);
                // Load without subscription as a fallback
              });
          });
      } else {
        dispatch(fetchDailyWordsRemaining());
      }
    });
  };
};

export const SET_SETTING_VALUE = 'SET_SETTING_VALUE';
export const setSettingValue = (field, value) => {
  if (field === 'interfaceLanguage') {
    onChangeInterfaceLanguage(value);
  }

  return (dispatch, getState) => {
    let settingsPayload = { [field]: value };

    // This is the first time we're saving settings, save them all to firebase
    if (!getState().settings.proficiency) {
      const { interfaceLanguage, highlightIssues, translationLanguage } = getState().settings;

      settingsPayload = {
        interfaceLanguage,
        translationLanguage,
        highlightIssues,
        ...settingsPayload,
      };
    }

    saveSettings(getState().user.uid, settingsPayload);

    dispatch({
      type: SET_SETTING_VALUE,
      field,
      value,
    });
  };
};

export const setProficiency = (value) => setSettingValue('proficiency', value);
export const setWritingStyle = (value) => setSettingValue('writingStyle', value);
export const setInterfaceLanguage = (value) => setSettingValue('interfaceLanguage', value);
export const setCustomerId = (value) => setSettingValue('customerId', value);
export const setSubscription = (value) => {
  return (dispatch) => {
    dispatch(setSettingValue('subscription', value));
    dispatch(fetchDailyWordsRemaining());
  };
};

export const SUBSCRIPTION_CANCEL_PENDING = 'SUBSCRIPTION_CANCEL_PENDING';
export const SUBSCRIPTION_CANCEL_COMPLETE = 'SUBSCRIPTION_CANCEL_COMPLETE';
export const cancelSubscription = () => {
  return (dispatch, getState) => {
    dispatch({ type: SUBSCRIPTION_CANCEL_PENDING });

    axios
      .delete(`https://gcp.gramara.com/subscription?customerId=${getState().settings.customerId}`)
      .then((resp) => {
        dispatch(setSubscription(resp.data.subscription));
        dispatch({ type: SUBSCRIPTION_CANCEL_COMPLETE });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: SUBSCRIPTION_CANCEL_COMPLETE, error });
      });
  };
};

export const logout = () => {
  firebase.auth().signOut();
  localStorage.clear();
  window.location.reload();
};

export const SET_DAILY_WORDS_REMAINING = 'SET_DAILY_WORDS_REMAINING';
export const setDailyWordsRemaining = (dailyWordsRemaining) => ({
  type: SET_DAILY_WORDS_REMAINING,
  dailyWordsRemaining,
});

export const sendWritingActivity = (additions, onSuccess) => {
  return (dispatch, getState) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .post(
            'https://gcp.gramara.com/activity',
            { additions },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((resp) => {
            onSuccess && onSuccess();

            if (get(getState(), 'settings.subscription.status' === "Active")) {
              dispatch(setDailyWordsRemaining(9999));
            } else {
              if (resp.data && resp.data.dailyWordsRemaining) {
                dispatch(setDailyWordsRemaining(resp.data.dailyWordsRemaining));
              }
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch(setDailyWordsRemaining(1000));
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchDailyWordsRemaining = () => {
  return (dispatch, getState) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => {
        axios
          .get('https://gcp.gramara.com/activity', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((resp) => {
            if (get(getState(), 'settings.subscription.status' === "Active")) {
              dispatch(setDailyWordsRemaining(9999));
            } else {
              dispatch(setDailyWordsRemaining(resp.data.dailyWordsRemaining));
            }
          });
      });
  };
};

export const SET_EDITOR_CHANGED_AT = 'SET_EDITOR_CHANGED_AT';
export const editorChanged = () => ({
  type: SET_EDITOR_CHANGED_AT,
  editorChangedAt: new Date().getTime(),
});

export const SET_CHECK_GRAMMAR_REQUESTED = 'SET_CHECK_GRAMMAR_REQUESTED';
export const setCheckGrammarRequested = (checkGrammarRequested) => ({
  type: SET_CHECK_GRAMMAR_REQUESTED,
  checkGrammarRequested,
});

export const SET_ERROR_FETCHING_CORRECTIONS = 'SET_ERROR_FETCHING_CORRECTIONS';
export const setErrorFetchingCorrections = (errorFetchingCorrections) => ({
  type: SET_ERROR_FETCHING_CORRECTIONS,
  errorFetchingCorrections,
});

export const SET_SHOW_CORRECTIONS_LOADER = 'SET_SHOW_CORRECTIONS_LOADER';
export const setShowCorrectionsLoader = (showCorrectionsLoader) => ({
  type: SET_SHOW_CORRECTIONS_LOADER,
  showCorrectionsLoader,
});
