import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Editor as Slate } from 'slate-react';
import { Point, Range } from 'slate';
import { isKeyHotkey } from 'is-hotkey';
import MaterialButton from '@material-ui/core/Button';
import MaterialMenu from '@material-ui/core/Menu';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import MaterialListItemText from '@material-ui/core/ListItemText';
import MaterialListItemIcon from '@material-ui/core/ListItemIcon';
import MediaQuery from 'react-responsive';
import { withStyles } from '@material-ui/core/styles';
import { Button, Icon, Toolbar } from './components';
import { withTranslation } from 'react-i18next';

import { setWritingStyle } from '../actions';

import pen from './../icons/pen.svg';

import defaultWritingStyleBlue from './../icons/defaultblue.svg';
import defaultWritingStyleWhite from './../icons/defaultwhite.svg';
import defaultWritingStyleGray from './../icons/defaultgray.svg';

import conciseWritingStyleBlue from './../icons/conciseblue.svg';
import conciseWritingStyleWhite from './../icons/concisewhite.svg';
import conciseWritingStyleGray from './../icons/concisegray.svg';

import informalWritingStyleBlue from './../icons/informalblue.svg';
import informalWritingStyleWhite from './../icons/informalwhite.svg';
import informalWritingStyleGray from './../icons/informalgray.svg';

import formalWritingStyleBlue from './../icons/formalblue.svg';
import formalWritingStyleWhite from './../icons/formalwhite.svg';
import formalWritingStyleGray from './../icons/formalgray.svg';

import { WritingStyles } from '../constants';
import '../styles/Left.css';

const DEFAULT_NODE = 'paragraph';

const StyledMaterialMenu = withStyles({
  paper: {
    boxShadow: '5px 6px 42px -16px rgba(0,0,0,0.2);',
    paddingBottom: 8,
    borderRadius: 16,
    border: '1px solid white',
  },
})((props) => (
  <MaterialMenu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMaterialMenuItem = withStyles((theme) => ({
  root: {
    marginTop: 0,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: '1rem',
    alignItems: 'flex-start',
    '&:focus, &:hover': {
      backgroundColor: 'unset',
    },
    '& .blue, & .white': {
      display: 'none',
    },
    '& .gray': {
      display: 'block',
    },
    '&:focus, &:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#334cff',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary, & .MuiListItemText-secondary': {
        color: 'white',
      },
      '& .gray, & .blue, & .active': {
        display: 'none',
      },
      '& .white': {
        display: 'block',
      },
    },
    '& .MuiListItemText-primary': {
      fontSize: '0.875rem',
      color: '#747d93',
      lineHeight: '16px',
    },
    '&.available .MuiListItemText-primary': {
      color: '#334cff',
    },
    '& .MuiListItemText-secondary': {
      fontSize: '0.8rem',
      color: '#bdc4db',
      lineHeight: 'normal',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 24,
      height: '1rem',
      marginTop: 6,
    },
  },
}))(MaterialMenuItem);

class Editor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      writingStyleAnchor: null,
    };
  }

  hasMark = (type) => {
    return this.props.value.activeMarks.some((mark) => mark.type === type);
  };

  hasBlock = (type) => {
    return this.props.value.blocks.some((node) => node.type === type);
  };

  renderMarkButton = (type, icon) => {
    const isActive = this.hasMark(type);

    return (
      <Button active={isActive} onMouseDown={(event) => this.onClickMark(event, type)}>
        <Icon>{icon}</Icon>
      </Button>
    );
  };

  renderDecoration = (props, editor, next) => {
    switch (props.decoration.type) {
      case 'mistake': {
        let style = {};
        if (this.props.highlightIssues) {
          let opacity = props.decoration.data.get('opacity');
          if (opacity == undefined) {
            opacity = 1;
          }

          style = {
            backgroundColor: `rgba(255, 255, 0, ${opacity})`,
          };
        }
        return (
          <span className="annotation-mistake" style={style} {...props.attributes}>
            {props.children}
          </span>
        );
      }
      case 'test':
        return (
          <span {...props.attributes} className="annotation-test">
            {props.children}
          </span>
        );
      case 'segment': {
        return (
          <span {...props.attributes} className="annotation-segment">
            {props.children}
          </span>
        );
      }
      case 'underline':
        return (
          <span {...props.attributes} className="annotation-underline">
            {props.children}
          </span>
        );
      case 'placeholder':
        return (
          <span {...props.attributes} className="placeholder">
            {props.children}
          </span>
        );
      default:
        return next();
    }
  };

  onClickMark = (event, type) => {
    event.preventDefault();
    this.props.editor.toggleMark(type);
  };

  onClickBlock = (event, type) => {
    event.preventDefault();

    // Handle everything but list buttons.
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock('list-item');

      if (isList) {
        return this.editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      }
      return this.editor.setBlocks(isActive ? DEFAULT_NODE : type);
    }

    // Handle the extra wrapping required for list buttons.
    const isList = this.hasBlock('list-item');
    const isType = this.editor.value.blocks.some((block) => {
      return !!this.editor.value.document.getClosest(block.key, (parent) => parent.type === type);
    });

    if (isList && isType) {
      return this.editor
        .setBlocks(DEFAULT_NODE)
        .unwrapBlock('bulleted-list')
        .unwrapBlock('numbered-list');
    }

    if (isList) {
      return this.editor.unwrapBlock(type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list').wrapBlock(type);
    }

    return this.editor.setBlocks('list-item').wrapBlock(type);
  };

  onClickWritingStyle = (event) => {
    this.setState({
      writingStyleAnchor: event.currentTarget,
    });
  };

  onSelectWritingStyle = (writingStyle) => {
    if (writingStyle !== WritingStyles.DEFAULT && !this.props.subscription) {
      return;
    }

    this.props.setWritingStyle(writingStyle);
    this.onCloseWritingStyle();
  };

  onCloseWritingStyle = () => {
    this.setState({
      writingStyleAnchor: null,
    });
  };

  decorateNode = (node, editor, next) => {
    const others = next();
    const segments = node.data.get('segments');
    const decorations = [...others];

    const [first] = node.texts();
    const [firstNode, firstPath] = first;

    if (segments && segments.length) {
      const selected = segments
        .filter((segment) => segment.selected)
        .map((segment) => ({
          type: 'underline',
          anchor: { key: firstNode.key, offset: segment.start, path: firstPath },
          focus: {
            key: firstNode.key,
            offset: segment.end,
            path: firstPath,
          },
        }));
      decorations.push(...selected);

      const mistakes = [];
      segments.map((segment) => {
        segment.mistakes.map((mistake) => {
          mistakes.push({
            type: 'mistake',
            anchor: { key: firstNode.key, offset: mistake.start, path: firstPath },
            focus: {
              key: firstNode.key,
              offset: mistake.end,
              path: firstPath,
            },
            data: {
              opacity: mistake.opacity,
            },
          });
        });
      });

      decorations.push(...mistakes);
    }

    if (node.data.get('placeholder')) {
      decorations.push({
        type: 'placeholder',
        anchor: { key: firstNode.key, offset: 0, path: firstPath },
        focus: {
          key: firstNode.key,
          offset: 100,
          path: firstPath,
        },
      });
    } else {
      // No placeholder and no segments yet, highlight any sentence we have for UX
      if (!segments || !segments.length) {
        decorations.push({
          type: 'underline',
          anchor: { key: firstNode.key, offset: 0, path: firstPath },
          focus: {
            key: firstNode.key,
            offset: 100,
            path: firstPath,
          },
        });
      }
    }

    return decorations;
  };

  render() {
    const { writingStyle, t } = this.props;
    const freePlan = !this.props.subscription;

    let writingStyleLabel = t('common:writingStyle');

    if (writingStyle === WritingStyles.CONCISE) {
      writingStyleLabel = t('common:conciseWritingStyle');
    } else if (writingStyle === WritingStyles.FORMAL) {
      writingStyleLabel = t('common:formalWritingStyle');
    } else if (writingStyle === WritingStyles.INFORMAL) {
      writingStyleLabel = t('common:conversationWritingStyle');
    }

    return (
      <div className="editor-container">
        <div className="editor">
          <MediaQuery minWidth={769}>
            <MaterialButton
              variant="contained"
              color="primary"
              className="editor-button"
              aria-controls="writing-style-menu"
              aria-haspopup="true"
              onClick={this.onClickWritingStyle}
            >
              <img src={pen} alt="" /> {writingStyleLabel}
            </MaterialButton>
            <StyledMaterialMenu
              disableAutoFocusItem
              keepMounted
              id="writing-style-menu"
              anchorEl={this.state.writingStyleAnchor}
              open={Boolean(this.state.writingStyleAnchor)}
              onClose={this.onCloseWritingStyle}
            >
              <StyledMaterialMenuItem
                selected={writingStyle === WritingStyles.DEFAULT}
                onClick={() => this.onSelectWritingStyle(WritingStyles.DEFAULT)}
              >
                <MaterialListItemIcon>
                  <img className="blue" src={defaultWritingStyleBlue} alt="" width="12px" height="12px" />
                  <img className="white" src={defaultWritingStyleWhite} alt="" width="12px" height="12px" />
                  <img className="gray" src={defaultWritingStyleGray} alt="" width="12px" height="12px" />
                </MaterialListItemIcon>
                <MaterialListItemText primary={t('common:defaultWritingStyle')} />
              </StyledMaterialMenuItem>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="inline-divider" />
                <div
                  style={{
                    flex: 2,
                    textAlign: 'center',
                    padding: '14px 11px',
                    fontSize: '0.8rem',
                    color: '#747d93',
                  }}
                >
                  Gramara Pro
                </div>
                <div className="inline-divider" />
              </div>
              <StyledMaterialMenuItem
                disabled={freePlan}
                selected={writingStyle === WritingStyles.CONCISE}
                onClick={() => this.onSelectWritingStyle(WritingStyles.CONCISE)}
              >
                <MaterialListItemIcon>
                  <img
                    className="blue"
                    src={conciseWritingStyleBlue}
                    alt=""
                    width="13px"
                    height="10px"
                    style={{ marginTop: 4 }}
                  />
                  <img
                    className="white"
                    src={conciseWritingStyleWhite}
                    alt=""
                    width="13px"
                    height="10px"
                    style={{ marginTop: 4 }}
                  />
                  <img
                    className="gray"
                    src={conciseWritingStyleGray}
                    alt=""
                    width="13px"
                    height="10px"
                    style={{ marginTop: 4 }}
                  />
                </MaterialListItemIcon>
                <MaterialListItemText
                  primary={t('common:conciseWritingStyle')}
                  secondary={t('common:conciseWritingStyleSubtitle')}
                />
              </StyledMaterialMenuItem>
              <StyledMaterialMenuItem
                disabled={freePlan}
                selected={writingStyle === WritingStyles.FORMAL}
                onClick={() => this.onSelectWritingStyle(WritingStyles.FORMAL)}
              >
                <MaterialListItemIcon>
                  <img
                    className="blue"
                    src={formalWritingStyleBlue}
                    alt=""
                    width="13px"
                    height="15px"
                    style={{ marginTop: 1 }}
                  />
                  <img
                    className="white"
                    src={formalWritingStyleWhite}
                    alt=""
                    width="13px"
                    height="15px"
                    style={{ marginTop: 1 }}
                  />
                  <img
                    className="gray"
                    src={formalWritingStyleGray}
                    alt=""
                    width="13px"
                    height="15px"
                    style={{ marginTop: 1 }}
                  />
                </MaterialListItemIcon>
                <MaterialListItemText
                  primary={t('common:formalWritingStyle')}
                  secondary={t('common:formalWritingStyleSubtitle')}
                />
              </StyledMaterialMenuItem>
              <StyledMaterialMenuItem
                disabled={freePlan}
                selected={writingStyle === WritingStyles.INFORMAL}
                onClick={() => this.onSelectWritingStyle(WritingStyles.INFORMAL)}
              >
                <MaterialListItemIcon>
                  <img className="blue" src={informalWritingStyleBlue} alt="" width="14px" height="16px" />
                  <img className="white" src={informalWritingStyleWhite} alt="" width="14px" height="16px" />
                  <img className="gray" src={informalWritingStyleGray} alt="" width="14px" height="16px" />
                </MaterialListItemIcon>
                <MaterialListItemText
                  primary={t('common:conversationWritingStyle')}
                  secondary={t('common:conversationWritingStyleSubtitle')}
                />
              </StyledMaterialMenuItem>
            </StyledMaterialMenu>
          </MediaQuery>

          <Slate
            ref={this.props.editorRef}
            value={this.props.value}
            onPaste={this.props.onPaste}
            onChange={this.props.onChange}
            renderDecoration={this.renderDecoration}
            decorateNode={this.decorateNode}
            className="editor-area"
          />
          {this.props.hasHitWordLimit && (
            <div
              style={{
                fontSize: 18,
                fontWeight: 600,
                color: 'red',
              }}
            >
              {t('common:reachedWordLimit')}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    writingStyle: state.settings.writingStyle,
    subscription: state.settings.subscription,
    highlightIssues: state.settings.highlightIssues,
  }),
  (dispatch) => ({
    setWritingStyle: bindActionCreators(setWritingStyle, dispatch),
  })
)(withTranslation()(Editor));
