import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Range, Value } from 'slate';
import Plain from 'slate-plain-serializer';
import { withTranslation } from 'react-i18next';
import escapeRegExp from 'lodash/escapeRegExp';
import { bindActionCreators } from 'redux';
import { clearCache, editorChanged, setCheckGrammarRequested, setShowCorrectionsLoader } from '../actions';
import map from 'lodash/map';

import Nav from './Nav';
import Editor from './Editor';
import Corrections from './Corrections';
import Settings from '../Settings';
import Account from '../Account';

class GramarEditor extends Component {
  caret = 0;
  editor = null;
  currentSegment = null;

  buildPlaceholder = (t) =>
    Value.fromJSON({
      document: {
        nodes: [
          {
            object: 'block',
            type: 'paragraph',
            data: {
              placeholder: true,
            },
            nodes: [
              {
                object: 'text',
                text: t('common:writeHereToGetStarted'),
              },
            ],
          },
        ],
      },
    });

  constructor(props) {
    super(props);

    this.state = {
      value: this.buildPlaceholder(props.t),
      corrections: [],
      active: 0,
      apply: false,
      currentSegment: null,
      showPlaceholder: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.cache) !== JSON.stringify(prevProps.cache)) {
      this.setAllDecorations(this.props.cache);
    }

    if (prevProps.writingStyle && this.props.writingStyle && prevProps.writingStyle !== this.props.writingStyle) {
      this.props.clearCache();
      this.setState({
        forceRefresh: true,
        corrections: 'fetching',
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.value.document.text.length === 0 && nextProps.interfaceLanguage !== this.props.interfaceLanguage) {
      this.setState({
        value: this.buildPlaceholder(nextProps.t),
        showPlaceholder: true,
      });
    }

    if (nextProps.checkGrammarRequested && !this.props.checkGrammarRequested) {
      this.props.setCheckGrammarRequested(false);

      const next = {
        text: !this.editor.value.document.text
          ? null
          : this.editor.value.document
              .getBlocks()
              .map((block, lineIndex) => {
                const { data, text } = block;
                const { lineId, segments } = data.toJS();
                return { text, lineIndex, lineId, segments };
              })
              .toJS(),
        apply: this.state.apply,
      };
      // todo send sentenceId to startSegmentation
      this.props.startSegmentation.next(next);
    }
  }

  editorRef = (editor) => {
    this.editor = editor;
  };

  setAllDecorations = (cache) => {
    const value = this.editor.value;
    const curSelection = value.selection;

    const blocks = this.editor.value.document.getBlocks();
    if (blocks.size === 0) {
      this.setState({
        corrections: [],
        currentSegment: null,
      });
      return;
    }

    const curBlockNode = value.document.getParent(curSelection.anchor.key);
    let setSelected = false;

    blocks.map((block) => {
      this.editor.setNodeByKey(block.key, {
        data: {
          segments: [],
        },
      });

      const segments = [];
      let lineId;

      cache
        .filter(({ isUsed }) => isUsed)
        .forEach(({ lineId: sentenceLineId, mistakes, origin: sentence, sentenceId }) => {
          lineId = sentenceLineId;
          const match = block.text.match(escapeRegExp(sentence));
          if (!match) {
            return;
          }

          const { index: sentenceStart } = match;
          const sentenceEnd = sentenceStart + sentence.length;

          let selected = false;
          if (curBlockNode && curBlockNode.key === block.key) {
            const curPos = curSelection.anchor.offset;
            if (curPos >= sentenceStart && curPos <= sentenceEnd) {
              selected = true;
              setSelected = true;
            }
          }

          const newSegment = {
            start: sentenceStart,
            end: sentenceEnd,
            sentence,
            selected,
            blockPath: value.document.getPath(block),
            mistakes: [],
            sentenceId,
            lineId: sentenceLineId,
          };

          if (selected) {
            this.props.setShowCorrectionsLoader(false);
            const { active, corrections } = this.setCorrection(sentence);
            this.setState({ currentSegment: newSegment, active, corrections });
          }

          const hasMistakes = mistakes && Array.isArray(mistakes);
          if (hasMistakes) {
            mistakes.forEach(([mistakeStart, mistakeEnd, opacity]) => {
              newSegment.mistakes.push({
                start: sentenceStart + mistakeStart,
                end: sentenceStart + mistakeEnd,
                opacity,
              });
            });
          }

          segments.push(newSegment);
        });

      const data = {
        lineId,
        segments,
      };
      this.editor.setNodeByKey(block.key, { data });
    });

    if (!setSelected) {
      this.setState({
        corrections: [],
        currentSegment: null,
      });
    }
  };

  toggleCorrectionNav = (active) => this.setState({ active });

  onPaste = (event, editor, next) => {
    // on paste, new line ids and sentence ids will be generated
    const text = this.state.value.document
      .getBlocks()
      .map(({ text }, lineIndex) => {
        return { text, lineIndex };
      })
      .toJS();

    this.props.startSegmentation.next({
      text,
      apply: true,
    });
    return next();
  };

  setCorrection = (sentence) => {
    const hasCorrections = this.props.cache.find(({ origin }) => sentence === origin);
    const corrections = sentence ? (hasCorrections ? hasCorrections.correction : 'fetching') : [];

    if (corrections && this.state.corrections) {
      if (
        corrections !== 'fetching' &&
        this.state.corrections !== 'fetching' &&
        corrections.join() === this.state.corrections.join()
      ) {
        return {
          active: this.state.active,
          corrections,
        };
      }
    }

    return {
      active: 0,
      corrections,
    };
  };

  hitWordLimit = (value) => {
    const wordLimit = this.props.subscription ? 9999 : 500;
    const hitWordLimit = value.document.getText().split(' ').length > wordLimit;

    if (hitWordLimit) {
      const lastWordIdx = value.document.getText().lastIndexOf(' ');
      if (lastWordIdx > -1) {
        this.setState({
          value: Plain.deserialize(value.document.getText().substr(0, lastWordIdx)),
        });
        return true;
      }
    }

    return false;
  };

  onChange = ({ value }) => {
    const curSelection = value.selection;
    const lastSelection = this.state.value.selection;

    let lastBlockNode = null;
    let curBlockNode = null;

    if (lastSelection) {
      lastBlockNode = this.editor.value.document.getParent(lastSelection.anchor.key);
    }

    if (curSelection) {
      curBlockNode = value.document.getParent(curSelection.anchor.key);
    }

    if (this.state.showPlaceholder && curBlockNode) {
      this.setState({ showPlaceholder: false });
      const [first] = curBlockNode.texts();
      const [firstNode] = first;
      const firstPath = this.state.value.document.getPath(firstNode);

      this.editor.removeTextByPath(firstPath, 0, curBlockNode.getText().length);
      return;
    }

    const blocks = value.document.getBlocks();
    let shouldStartSegment =
      this.state.value.document.text !== value.document.text ||
      this.state.value.document.getBlocks().size !== blocks.size ||
      this.state.forceRefresh;

    const justAppliedCorrection = this.state.apply;

    // We need to track changes manually on Android
    if (!this.state.forceRefresh && window.isAndroid && !justAppliedCorrection) {
      shouldStartSegment = false;
    }

    if (!justAppliedCorrection && window.isAndroid && this.state.value.document.text !== value.document.text) {
      this.props.editorChanged();
    }

    this.setState(
      {
        value,
        apply: false,
        forceRefresh: false,
      },
      () => {
        if (
          curSelection &&
          lastSelection &&
          (curSelection.anchor.offset !== lastSelection.anchor.offset ||
            justAppliedCorrection ||
            (curBlockNode && lastBlockNode && curBlockNode.key !== lastBlockNode.key))
        ) {
          const lastBlockNodeKey = lastBlockNode && lastBlockNode.key;
          const curBlockNodeKey = curBlockNode && curBlockNode.key;
          if (curBlockNode && lastBlockNode && (lastBlockNodeKey !== curBlockNodeKey || justAppliedCorrection)) {
            const data = lastBlockNode.data.toJS();
            const segments = data.segments || [];
            segments.map((segment) => {
              segment.selected = false;
            });

            this.editor.setNodeByKey(lastBlockNode.key, {
              data: {
                ...data,
                segments,
              },
            });
          }

          if (curBlockNode) {
            const data = curBlockNode.data.toJS();
            const segments = data.segments || [];

            segments.map((segment) => {
              const curPos = curSelection.anchor.offset;
              segment.selected = curPos >= segment.start && curPos < segment.end;

              if (segment.selected && !justAppliedCorrection) {
                const { active, corrections } = this.setCorrection(segment.sentence);
                this.setState({ active, corrections, currentSegment: segment });
              }
            });

            this.editor.setNodeByKey(curBlockNode.key, {
              data: {
                ...data,
                segments,
              },
            });
          }
        }

        if (shouldStartSegment) {
          // todo send sentenceId to startSegmentation
          this.props.startSegmentation.next({
            text: !value.document.text
              ? null
              : value.document
                  .getBlocks()
                  .map((block, lineIndex) => {
                    const { data, text } = block;
                    const { lineId, segments } = data.toJS();
                    return { text, lineIndex, lineId, segments };
                  })
                  .toJS(),
            apply: this.state.apply,
          });
        }
      }
    );
  };

  applyText = () => {
    if (!this.state.currentSegment || this.state.corrections === 'fetching') {
      return null;
    }

    const segment = this.state.currentSegment;
    const block = this.editor.value.document.getNode(segment.blockPath);

    const [first] = block.texts();
    const [firstNode] = first;
    const firstPath = this.editor.value.document.getPath(firstNode);

    const range = Range.create({
      anchor: { key: firstNode.key, offset: segment.start, path: firstPath },
      focus: {
        key: firstNode.key,
        offset: segment.end,
        path: firstPath,
      },
    });

    const space = segment['sentence'].slice(segment['sentence'].length - 1) === ' ' ? ' ' : '';
    const text = this.state.corrections[this.state.active] + space;
    const oldData = block.data.toJS();
    const { segments } = oldData;
    const segmentsUpdate = map(segments, (s) => (s.sentenceId === segment.sentenceId ? { ...s, changingTo: text } : s));

    if (range.isSet) {
      this.setState({ apply: true, corrections: 'fetching' }, () => {
        this.editor.insertTextAtRange(range, text).setNodeByPath(segment.blockPath, {
          data: {
            ...oldData,
            segments: segmentsUpdate,
          },
        });
      });
    }
  };

  applyWord = (word) => {
    if (!this.state.currentSegment || this.state.corrections === 'fetching') {
      return null;
    }

    const segment = this.state.currentSegment;
    const block = this.editor.value.document.getNode(segment.blockPath);
    const oldData = block.data.toJS();
    const { segments } = oldData;
    const segmentsUpdate = map(segments, (s) => (s.sentenceId === segment.sentenceId ? { ...s, changingTo: word } : s));

    const [first] = block.texts();
    const [firstNode] = first;
    const firstPath = this.editor.value.document.getPath(firstNode);

    const range = Range.create({
      anchor: { key: firstNode.key, offset: segment.start, path: firstPath },
      focus: {
        key: firstNode.key,
        offset: segment.end,
        path: firstPath,
      },
    });

    if (range.isSet) {
      this.setState({ apply: true, corrections: 'fetching' }, () => {
        this.editor.insertTextAtRange(range, word).setNodeByPath(segment.blockPath, {
          data: {
            ...oldData,
            segments: segmentsUpdate,
          },
        });
      });
    }
  };

  render() {
    const { proficiency, showCorrectionsLoader } = this.props;
    const corrections = showCorrectionsLoader ? 'fetching' : this.state.corrections;

    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <Nav />
        </MediaQuery>
        <MediaQuery minWidth={769}>
          <Settings />
          <Account />
        </MediaQuery>
        {proficiency && (
          <Fragment>
            <Editor
              onChange={this.onChange}
              onPaste={this.onPaste}
              value={this.state.value}
              editor={this.editor}
              editorRef={this.editorRef}
              settings={this.props.settings}
              hasHitWordLimit={this.state.hasHitWordLimit}
            />
            <Corrections
              currentSegment={this.state.currentSegment}
              corrections={corrections}
              active={this.state.active}
              editor={this.editor}
              cache={this.props.cache.filter(({ isUsed }) => isUsed)}
              toggleCorrectionNav={this.toggleCorrectionNav}
              applyText={this.applyText}
              applyWord={this.applyWord}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cache: state.sentences,
  proficiency: state.settings.proficiency,
  subscription: state.settings.subscription,
  writingStyle: state.settings.writingStyle,
  checkGrammarRequested: state.settings.checkGrammarRequested,
  interfaceLanguage: state.settings.interfaceLanguage,
  showCorrectionsLoader: state.settings.showCorrectionsLoader,
});

const mapDispatchToProps = (dispatch) => ({
  clearCache: bindActionCreators(clearCache, dispatch),
  editorChanged: bindActionCreators(editorChanged, dispatch),
  setCheckGrammarRequested: bindActionCreators(setCheckGrammarRequested, dispatch),
  setShowCorrectionsLoader: bindActionCreators(setShowCorrectionsLoader, dispatch),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GramarEditor));
