import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.GRAMARA_FIREBASE_API_KEY,
  authDomain: process.env.GRAMARA_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GRAMARA_FIREBASE_DATABASE_URL,
  projectId: process.env.GRAMARA_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GRAMARA_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GRAMARA_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GRAMARA_FIREBASE_APP_ID,
};

firebase.initializeApp(config);

export default firebase;

export const firestore = firebase.firestore();

export const keys = {
  USER_SETTINGS_COLLECTION: 'user_settings',
};

export const getSettings = (userId, callback) => {
  firestore
    .collection(keys.USER_SETTINGS_COLLECTION)
    .doc(userId)
    .get()
    .then(callback);
};

export const saveSettings = (userId, settings, callback) => {
  firestore
    .collection(keys.USER_SETTINGS_COLLECTION)
    .doc(userId)
    .set(settings, { merge: true })
    .then(callback);
};
