import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import MaterialIconButton from '@material-ui/core/IconButton';
import closeIcon from './../icons/close.svg';
import backArrowBlueIcon from './../icons/backarrowblue.svg';
import { hideAccountModal } from '../actions';
import GramaraPro from './GramaraPro';
import AccountSettings from './AccountSettings';

import '../styles/Settings.css';
import '../styles/Account.css'

import { useTranslation } from 'react-i18next';

export default () => {
  const [currentTab, setCurrentTab] = useState("gramaraPro");
  const accountModalVisible = useSelector(state => state.settings.accountModalVisible)
  const showCloseButton = useSelector(state => state.settings.modalProps.showCloseButton)
  const dispatch = useDispatch()
  const { t } = useTranslation();

  if (!accountModalVisible) {
    return null;
  }

  return (
    <div className="settings account" style={{ backgroundColor: 'rgba(51,76,255, 0.7)' }}>
      <div className="card">
        <div className="header-tabs">
          {showCloseButton && <div className="back-button" onClick={() => dispatch(hideAccountModal())}>
            <img src={backArrowBlueIcon} className="back-arrow" alt="" />
            <span className="back-text">{t("common:settingsBack")}</span>
          </div>}
          <h2 className={classNames({ selected: currentTab === "gramaraPro" })} onClick={() => setCurrentTab("gramaraPro")}>
            <span>Gramara Pro</span>
          </h2>
          <h2 className={classNames({ selected: currentTab === "accountSettings" })} onClick={() => setCurrentTab("accountSettings")}>
            {t("common:accountSettings")}
          </h2>
        </div>
        {showCloseButton && <MaterialIconButton className="close-btn" onClick={() => dispatch(hideAccountModal())} >
          <img className="close-icon" src={closeIcon} alt="" />
        </MaterialIconButton>}
        {currentTab === "gramaraPro" && <GramaraPro />}
        {currentTab === "accountSettings" && <AccountSettings />}
      </div>
    </div>
  );
};