import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { Provider, useDispatch, useSelector } from 'react-redux';
import FullScreenLoader from './FullScreenLoader';
import thunk from 'redux-thunk';
import UAParser from 'ua-parser-js/dist/ua-parser.min';
import firebase from 'firebase';
import App from './App';
import SignIn from './SignIn';
import * as serviceWorker from './serviceWorker';
import userReducer from './reducers/user';
import sentenceReducer from './reducers/sentences';
import settingsReducer from './reducers/settings';
import { setUserInfo } from './actions';
import './i18n';
import './styles/index.css';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-52772108-15');
ReactGA.pageview(window.location.pathname + window.location.search);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({ sentences: sentenceReducer, settings: settingsReducer, user: userReducer }),
  composeEnhancers(applyMiddleware(thunk))
);

const Wrapper = () => {
  const userLoaded = useSelector((state) => Boolean(state.user.uid));
  const [firebaseAuthComplete, setFirebaseAuthComplete] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const unregisterObserver = firebase.auth().onAuthStateChanged(() => {
      setFirebaseAuthComplete(true);
    });

    return () => unregisterObserver();
  });

  if (!firebaseAuthComplete) {
    return <FullScreenLoader />;
  }

  const { name: osName } = new UAParser().getOS();
  window.isAndroid = osName.toLowerCase() === 'android';

  if (!userLoaded) {
    return <SignIn onSignIn={(user) => user && dispatch(setUserInfo(user))} />;
  }

  return <App />;
};

ReactDOM.render(
  <Provider store={store}>
    <Wrapper />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
