import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from './components';
import MaterialButton from '@material-ui/core/Button';
import { useTranslation, t, Trans } from 'react-i18next';

import { showSettingsModal, showAccountModal, setCheckGrammarRequested, setErrorFetchingCorrections } from '../actions';
import acc from './../icons/acc.svg';
import setting from './../icons/setting.svg';
import check from './../icons/check.svg';
import refresh from './../icons/refresh.svg';
import '../styles/Right.css';
import CorrectionRemovedWord from './CorrectionRemovedWord';

const renderCorrections = (props) => {
  if (!(props.currentSegment && props.corrections[props.active])) {
    return props.corrections[props.active];
  }

  const delta = props.getDelta();

  return delta.map((correction, key, arr) => {
    if (correction.removed) {
      return (
        <CorrectionRemovedWord
          key={key}
          zIndex={arr.length - key}
          removeWord={() => props.replaceWord(delta, correction, key)}
          word={correction.value}
          hover
        />
      )
    }

    if (correction.added) {
      return (
        <span key={key} onClick={() => props.replaceWord(delta, correction, key)} className="correction-word">
          {correction.value}
        </span>
      );
    }

    return <span key={key}>{correction.value}</span>;
  });
};

export default (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dailyWordsRemaining = useSelector((state) => state.settings.dailyWordsRemaining);
  const subscription = useSelector((state) => state.settings.subscription);
  const errorFetchingCorrections = useSelector((state) => state.settings.errorFetchingCorrections);
  const wordLimit = subscription ? null : 1000;

  const editorChangedAt = useSelector((state) => state.settings.editorChangedAt || 0);
  const [editorLastChangedAt, setEditorLastChangedAt] = useState(editorChangedAt);

  let showCheckGrammarButton = false;
  if ((window.isAndroid && editorChangedAt > editorLastChangedAt) || errorFetchingCorrections) {
    showCheckGrammarButton = true;
  }

  return (
    <div className="correction-container">
      <div className="correction">
        <div className="correction-buttons">
          <MaterialButton variant="contained" color="primary" onClick={() => dispatch(showSettingsModal())}>
            <img src={setting} alt="" /> {t('common:settings')}
          </MaterialButton>

          <MaterialButton
            variant="contained"
            color="primary"
            className="active"
            onClick={() => dispatch(showAccountModal())}
          >
            <img src={acc} alt="" /> {t('common:account')}
          </MaterialButton>
        </div>
        {dailyWordsRemaining <= 0 && (
          <div>
            <div>{t('common:noDailyWordsRemaining')}</div>
            <div>
              <MaterialButton variant="contained" color="primary" onClick={() => dispatch(showAccountModal())}>
                {t('common:learnAboutGramaraPro')}
              </MaterialButton>
            </div>
          </div>
        )}
        {dailyWordsRemaining > 0 && (
          <Fragment>
            {errorFetchingCorrections && (
              <MaterialButton
                variant="contained"
                color="primary"
                className="active"
                onClick={() => {
                  dispatch(setCheckGrammarRequested(true));
                  dispatch(setErrorFetchingCorrections(false));
                  setEditorLastChangedAt(editorChangedAt);
                }}
                style={{
                  paddingLeft: 12,
                  paddingRight: 12,
                  whiteSpace: 'nowrap',
                  borderRadius: 8,
                  marginBottom: 40,
                }}
              >
                <Icon style={{ marginRight: 8 }}>refresh</Icon>
                {t('common:retry')}
              </MaterialButton>
            )}
            {!errorFetchingCorrections && (
              <Fragment>
                {showCheckGrammarButton && (
                  <MaterialButton
                    variant="contained"
                    color="primary"
                    className="active"
                    onClick={() => {
                      dispatch(setCheckGrammarRequested(true));
                      setEditorLastChangedAt(editorChangedAt);
                    }}
                    style={{
                      paddingLeft: 12,
                      paddingRight: 12,
                      whiteSpace: 'nowrap',
                      borderRadius: 8,
                      marginBottom: 40,
                    }}
                  >
                    <Icon style={{ marginRight: 8 }}>refresh</Icon>
                    {t('common:checkGrammar')}
                  </MaterialButton>
                )}

                <ul className="correction-nav">
                  {props.corrections === 'fetching'
                    ? null
                    : (props.corrections || []).map((correction, index) => props.renderCorrectionNav(index))}
                </ul>
                {props.corrections === 'fetching' ? (
                  <div className="correction-spinner" />
                ) : props.corrections.length ? (
                  <div className="correction-block">
                    <span>{renderCorrections(props)}</span>
                    {props.translation && <div style={{ marginTop: 16, color: 'black' }}>{props.translation}</div>}
                    <span className="correction-block-line" />
                    <span onClick={props.applyText} className="correction-block-apply">
                      <Icon>keyboard_backspace</Icon> {t('common:applyCorrection')}
                    </span>
                  </div>
                ) : null}
                {!Boolean(props.corrections.length) && Boolean(props.cache.length) && (
                  <div className="correction-empty">
                    {Boolean(props.currentSegment) ? (
                      <Fragment>
                        <Icon>check</Icon>
                        {t('common:noCorrectionsFound')}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Icon>keyboard_backspace</Icon> {t('common:clickToSeeCorrections')}
                      </Fragment>
                    )}
                  </div>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>

      {Boolean(wordLimit) && Boolean(dailyWordsRemaining) && (
        <div className="corrections-count">
          <div className="corrections-bar-wrapper">
            <div
              className="corrections-bar-inner"
              style={{ width: `${((1000.0 - dailyWordsRemaining) / 1000.0) * 100.0}%` }}
            />
          </div>
          <div className="corrections-count-text">
            <Trans i18nKey="common:wordsUsedCount">
              <span className="corrections-used">{{ current: 1000 - dailyWordsRemaining }}</span>
              {{ total: 1000 }}
            </Trans>
          </div>
        </div>
      )}
    </div>
  );
};
