import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialSwitch from '@material-ui/core/Switch';
import MaterialGrid from '@material-ui/core/Grid';
import MaterialFormGroup from '@material-ui/core/FormGroup';
import MaterialButton from '@material-ui/core/Button';
import MaterialHidden from '@material-ui/core/Hidden';
import MaterialTypography from '@material-ui/core/Typography';
import MaterialCircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTranslation, Trans } from 'react-i18next';
import axios from 'axios';
import i18next from 'i18next';

import FeatureCheckIcon from './../icons/featurecheck.svg';
import ProPlanIcon from './../icons/proplan.svg';
import dropin from 'braintree-web-drop-in';
import { setCustomerId, setSubscription } from '../actions';

const BigSwitch = withStyles((theme) => ({
  root: {
    width: 54,
    height: 28,
    padding: 0,
    margin: theme.spacing(1),
    marginTop: 2,
  },
  switchBase: {
    padding: 1,
    transform: 'translate(2px, 2px)',
    '&$checked': {
      transform: 'translate(28px, 1px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#334CFF',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    height: 26,
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    backgroundColor: '#E6EBFF',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <MaterialSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const BigSwitchLabel = ({ primary, secondary }) => (
  <div className="switch-label">
    <p style={{ marginTop: '6px', color: 'black' }}>{primary}</p>
    <p>{secondary}</p>
  </div>
);

const CompletePurchaseButton = withStyles((theme) => ({
  root: {
    background: '#60fc80',

    '&:hover, &:active, &:focus': {
      background: '#60fc80',
    },
  },
}))(MaterialButton);

const PLANS = {
  monthly: {
    id: 'gramara_monthly_15_usd',
    customerValue: 90,
  },
  yearly: {
    id: 'gramara_yearly_96_usd',
    customerValue: 96,
  },
};

export default () => {
  const { t } = useTranslation();
  const [payYearly, setPayYearly] = useState(false);
  const [purchaseClicked, setPurchaseClicked] = useState(false);
  const [purchasePending, setPurchasePending] = useState(false);
  const [braintreeInstance, setBraintreeInstance] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const subscription = useSelector((state) => state.settings.subscription);
  const customerId = useSelector((state) => state.settings.customerId);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const loadBraintreeInstance = () => {
    const braintreeLocales = {
      en: 'en_US',
      'zh-Hans': 'zh_CN',
      'zh-Hant': 'zh_HK',
      ja: 'ja_JP',
      ru: 'ru_RU',
      de: 'de_DE',
      uk: 'en_US',
      es: 'es_ES',
      pt: 'pt_BR',
    };

    dropin.create(
      {
        authorization: 'production_pg3ztrg2_c5dsb3fps6fmnrnb',
        container: '#braintree-dropin-container',
        paypal: {
          flow: 'vault',
        },
        card: {
          cardholderName: {
            required: true,
          },
        },
        locale: braintreeLocales[i18next.language] || 'en_US',
      },
      (createErr, instance) => {
        if (createErr) {
          setErrorMessage('Error communicating with payment processor');
          return;
        }
        setBraintreeInstance(instance);
      }
    );
  };

  const purchaseSubscription = (plan) => {
    const planId = plan.id;

    braintreeInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
      setPurchasePending(true);
      axios
        .post('https://gcp.gramara.com/subscription', {
          paymentMethodNonce: payload.nonce,
          planId: planId,
          email: user.email, // May not be present in the Login with Facebook case
        })
        .then((resp) => {
          const { data } = resp;
          setPurchasePending(false);

          if (data.error) {
            setErrorMessage(data.error);
            return;
          }

          dispatch(setCustomerId(data.customer.id));
          dispatch(setSubscription(data.subscription));

          try {
            // eslint-disable-next-line no-undef
            ga('send', 'event', 'subscribe', 'purchase', payYearly ? 'yearly' : 'monthly', plan.customerValue);
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => {
          setPurchasePending(false);
          setErrorMessage('Error completing purchase');
        });
      return false;
    });
  };

  const repurchaseSubscription = (customerId, planId) => {
    setPurchasePending(true);
    axios
      .post('https://gcp.gramara.com/subscription', {
        planId,
        customerId,
      })
      .then((resp) => {
        const { data } = resp;
        setPurchasePending(false);

        if (data.error) {
          setErrorMessage(data.error);
          return;
        }

        dispatch(setCustomerId(data.customer.id));
        dispatch(setSubscription(data.subscription));
      })
      .catch((err) => {
        setPurchasePending(false);
        setErrorMessage('Error completing purchase');
      });
    return false;
  };

  return (
    <div className="choices">
      <MaterialFormGroup>
        <MaterialGrid
          container
          className="settings-grid"
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <MaterialGrid item xs={12} sm={12} md={12} className="sub-toggle-row">
            <FormControlLabel
              className="switch-control"
              control={
                <BigSwitch
                  className="big-switch"
                  checked={payYearly}
                  onChange={(event) => {
                    setPayYearly(event.target.checked);
                  }}
                />
              }
              label={
                <div>
                  <BigSwitchLabel primary={t('common:yearlySubscription')} />
                </div>
              }
            />
          </MaterialGrid>
          <MaterialGrid container item xs={12} sm={12} md={12} alignItems="flex-start">
            <MaterialGrid item xs={12} sm={5} className="price-section free">
              <div className="price-heading-section">
                <h2 className="price-heading free">{t('common:priceFree')}</h2>
              </div>
              <div className="divider" />
              <div className="price-features">
                <div className="feature-section">
                  <img className="feature-checkmark" src={FeatureCheckIcon} alt="" />{' '}
                  <span className="feature"> {t('common:feature1000WordsPerDay')}</span>
                </div>
                <div className="feature-section">
                  <img className="feature-checkmark" src={FeatureCheckIcon} alt="" />{' '}
                  <span className="feature"> {t('common:feature500WordDocuments')}</span>
                </div>
                <div className="feature-section">
                  <img className="feature-checkmark" src={FeatureCheckIcon} alt="" />{' '}
                  <span className="feature"> {t('common:feature1WritingStyle')}</span>
                </div>
              </div>
            </MaterialGrid>

            <MaterialHidden smUp>
              <MaterialGrid item xs={12}>
                <div className="divider" />
              </MaterialGrid>
            </MaterialHidden>

            <MaterialGrid item xs={12} sm={7} className="price-section pro">
              <div className="price-heading-section">
                <h2 className="price-heading pro">
                  <img height="16px" width="16px" src={ProPlanIcon} alt="" />
                  &nbsp;{t('common:priceProfessional')}
                </h2>
                <div className="pricing-pro">
                  {Boolean(payYearly) && (
                    <div style={{ marginBottom: '0.4rem' }} className="price discount">
                      $180 / ${t('common:priceYear')}
                    </div>
                  )}
                  <div className="price">
                    {payYearly
                      ? `$96 / ${t('common:priceYear')} (${t('common:percentOff50')})`
                      : `$15 / ${t('common:priceMonth')}`}
                  </div>
                </div>
              </div>
              <div className="divider" />
              <div className="price-features">
                <div className="feature-section">
                  <img className="feature-checkmark" src={FeatureCheckIcon} alt="" />{' '}
                  <span className="feature"> {t('common:featureUnlimitedGrammarCorrection')}</span>
                </div>
                <div className="feature-section">
                  <img className="feature-checkmark" src={FeatureCheckIcon} alt="" />{' '}
                  <span className="feature"> {t('common:featureLongDocumentLength')}</span>
                </div>
                <div className="feature-section">
                  <img className="feature-checkmark" src={FeatureCheckIcon} alt="" />{' '}
                  <span className="feature"> {t('common:featureAllWritingStyles')}</span>
                </div>
                <div className="feature-section">
                  <img className="feature-checkmark" src={FeatureCheckIcon} alt="" />{' '}
                  <span className="feature"> {t('common:feature1User')}</span>
                </div>
              </div>
              {Boolean(subscription) && subscription.status !== 'Canceled' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <MaterialTypography color="primary">{t('common:subscriptionPurchased')}</MaterialTypography>
                </div>
              )}
              {(subscription == undefined || subscription.status === 'Canceled') && (
                <Fragment>
                  {!purchaseClicked && (
                    <div>
                      <MaterialButton
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setPurchaseClicked(true);
                          if (subscription && subscription.status === 'Canceled') {
                            repurchaseSubscription(customerId, payYearly ? PLANS.yearly.id : PLANS.monthly.id);
                            return;
                          }
                          loadBraintreeInstance();
                        }}
                      >
                        {subscription && subscription.status === 'Canceled' && t('common:resumeSubscription')}
                        {subscription == undefined && t('common:purchaseSubscription')}
                      </MaterialButton>
                      {subscription && subscription.status === 'Canceled' && (
                        <div
                          style={{
                            fontSize: '0.8rem',
                            color: 'black',
                            marginTop: '0.5rem',
                            lineHeight: 'normal',
                            textAlign: 'center',
                          }}
                        >
                          <Trans i18nKey="common:subscriptionResumesAfter">
                            {{ date: subscription.nextBillingDate }}
                          </Trans>
                        </div>
                      )}
                    </div>
                  )}

                  {purchaseClicked && !purchasePending && (
                    <div style={{ marginTop: '1rem' }}>
                      <div className="divider" />
                      <form>
                        <div id="braintree-dropin-container"></div>
                        <div id="braintree-purchase-msg">{errorMessage}</div>
                        {braintreeInstance && (
                          <CompletePurchaseButton
                            id="braintree-payment-button"
                            variant="contained"
                            onClick={() => purchaseSubscription(payYearly ? PLANS.yearly : PLANS.monthly)}
                          >
                            {t('common:completePurchase')}
                          </CompletePurchaseButton>
                        )}
                      </form>
                    </div>
                  )}
                  {purchasePending && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <MaterialCircularProgress />
                    </div>
                  )}
                </Fragment>
              )}
            </MaterialGrid>
          </MaterialGrid>
        </MaterialGrid>
      </MaterialFormGroup>
    </div>
  );
};
