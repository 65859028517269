export default {
  en: {
    common: {
      writingStyle: 'Writing Style',
      defaultWritingStyle: 'Default',
      conciseWritingStyle: 'Concise',
      conciseWritingStyleSubtitle: 'Short, direct',
      formalWritingStyle: 'Professional Document',
      formalWritingStyleSubtitle: 'Formal tone',
      conversationWritingStyle: 'Conversation',
      conversationWritingStyleSubtitle: 'Informal, friendly tone',
      writeHereToGetStarted: 'Write something here to get started',
      settings: 'Settings',
      account: 'Account',
      noCorrectionsFound: 'No corrections found',
      applyCorrection: 'Apply',
      highlightIssuesSetting: 'Highlight potential issues',
      highlightIssuesSettingDescription: 'Potential issues are highlighted in yellow',
      interfaceLanguageSetting: 'Interface Language',
      interfaceLanguageSettingDescription: 'Display the app menu in this language',
      backtranslationLanguageSetting: 'Translation Language',
      backtranslationLanguageSettingDescription: 'Translate corrected sentences to this language',
      lowProficiencyDescription: 'People have a hard time understanding my English',
      mediumProficiencyDescription: 'People usually understand my English, but it is clearly not my first language.',
      highProficiencyDescription: 'I am fluent in English, but make occasional grammatical errors',
      aiSystemProficiencyInfo:
        'Our AI system takes your English proficiency into account when generating corrections. Try a different level if you feel that the system under or over corrects.',
      proficiencySettingShort: 'Proficiency',
      proficiencySettingLong: 'English proficiency',
      appSettings: 'App settings',
      settingsNext: 'Next',
      settingsBack: 'back',
      accountSettings: 'Account settings',
      yearlySubscription: 'Yearly Subscription',
      priceFree: 'Free',
      priceProfessional: 'Professional',
      priceMonth: 'month',
      priceYear: 'year',
      percentOff50: '50% off',
      feature1000WordsPerDay: '1000 words per day',
      feature500WordDocuments: '500 word documents',
      feature1WritingStyle: '1 writing style',
      featureUnlimitedGrammarCorrection: 'Unlimited grammar correction',
      featureLongDocumentLength: '10,000 word limit',
      featureAllWritingStyles: 'Unlock all writing styles',
      feature1User: '1 user',
      subscriptionPurchased: 'Purchased',
      purchaseSubscription: 'Purchase',
      resumeSubscription: 'Resume subscription',
      completePurchase: 'Complete Purchase',
      canceledSubscriptionHasAccessUntil:
        'Your subscription was canceled, but you will continue to have access to Gramara Pro until <0>{{date}}</0>',
      subscriptionRenewsAt:
        'Your subscription is set to renew on <1>{{nextBillingDate}}</1> for <2>${{nextBillingPeriodAmount}}</2>',
      youAreOnTheFreePlan: 'You are currently on the free plan',
      myAccount: 'My account',
      cancelSubscription: 'Cancel subscription',
      changeMyPassword: 'Change my password',
      changePasswordEmailSentTo: 'Email sent to {{email}}',
      logout: 'Logout',
      subscriptionResumesAfter: "You won't be charged until your current subscription ends after {{date}}",
      noDailyWordsRemaining: "You've reached your daily word limit.",
      learnAboutGramaraPro: 'Learn more',
      reachedWordLimit: "You've reached the word limit",
      wordsUsedCount: '{{current}} / {{total}} words used',
      yearlyBilledAsOnePayment: 'Billed as one payment of ${{price}}',
      checkGrammar: 'Check Grammar',
      retry: 'Retry',
      clickToSeeCorrections: 'Click on a sentence to see corrections',
    },
  },
  'zh-Hans': {
    common: {
      writingStyle: '写作风格',
      highProficiencyDescription: '我英语流利，但偶尔会出现语法错误',
      aiSystemProficiencyInfo: '我们的AI系统在纠错时会考虑您的英语水平。 如果您认为系统不足或过剩，请尝试其他级别。',
      proficiencySettingShort: '熟练程写作风格',
      defaultWritingStyle: '默认',
      conciseWritingStyle: '简洁',
      conciseWritingStyleSubtitle: '简明，精练',
      formalWritingStyle: '书面语',
      formalWritingStyleSubtitle: '正式',
      conversationWritingStyle: '日常会话',
      conversationWritingStyleSubtitle: '非书面语，日常会话',
      writeHereToGetStarted: '从这里开始你的英文写作',
      settings: '设置',
      account: '帐户',
      noCorrectionsFound: '没有错误',
      applyCorrection: '纠错',
      highlightIssuesSetting: '标记错误',
      highlightIssuesSettingDescription: '错误以黄色标记',
      interfaceLanguageSetting: '界面语言',
      interfaceLanguageSettingDescription: '以所选语言显示应用程序菜单',
      backtranslationLanguageSetting: '翻译语言',
      backtranslationLanguageSettingDescription: '更正的英语翻译成所选的语言',
      lowProficiencyDescription: '人们很难理解我的英语',
      mediumProficiencyDescription: '英语不是我的母语，但一般人们能听得懂。',
      proficiencySettingLong: '英语能力',
      appSettings: '应用设定',
      settingsNext: '下一步',
      settingsBack: '上一步',
      accountSettings: '帐号设定',
      yearlySubscription: '年度订阅',
      priceFree: '免費',
      priceProfessional: '专业',
      priceMonth: '月费',
      priceYear: '年费',
      percentOff50: '5折',
      feature1000WordsPerDay: '每天1000字',
      feature500WordDocuments: '500字文档',
      feature1WritingStyle: '1种写作风格',
      featureUnlimitedGrammarCorrection: '语法无限制修正',
      featureLongDocumentLength: '10000字数限制',
      featureAllWritingStyles: '所有写作风格',
      feature1User: '当前用户',
      subscriptionPurchased: '已购买',
      purchaseSubscription: '购买',
      resumeSubscription: '恢复订阅',
      completePurchase: '购买完成',
      canceledSubscriptionHasAccessUntil: '您的购买已被取消，但您将继续有权使用Gramara Pro，直到 <0>{{date}}</0>',
      subscriptionRenewsAt:
        '您购买的服务将设置为在<1> {{nextBillingDate}} </1>上续订<2>${{nextBillingPeriodAmount}} </2>',
      youAreOnTheFreePlan: '您目前正在使用免费计划',
      myAccount: '我的帐户',
      cancelSubscription: '取消订购',
      changeMyPassword: '修改我的密码',
      changePasswordEmailSentTo: '修改密码的电子邮件发送至 {{email}}',
      logout: '登出',
      subscriptionResumesAfter: '在{{date}}之后，您当前的订阅到期后，您才需要付费',
      noDailyWordsRemaining: '您已达到每日字数限制',
      learnAboutGramaraPro: '了解更多',
      reachedWordLimit: '您已达到字数上限',
      wordsUsedCount: '使用 {{current}} / {{total}} 个字',
      yearlyBilledAsOnePayment: '记为$ {{price}}的一笔付款',
      checkGrammar: '验证语法',
      retry: '重试',
    },
  },
  'zh-Hant': {
    common: {
      writingStyle: '寫作風格',
      defaultWritingStyle: '默認',
      conciseWritingStyle: '簡潔',
      conciseWritingStyleSubtitle: '簡明，精練',
      formalWritingStyle: '書面語',
      formalWritingStyleSubtitle: '正式',
      conversationWritingStyle: '日常會話',
      conversationWritingStyleSubtitle: '非書面語，日常會話',
      writeHereToGetStarted: '從這裡開始你的英文寫作',
      settings: '設置',
      account: '帳戶',
      noCorrectionsFound: '沒有找到錯誤',
      applyCorrection: '糾錯',
      highlightIssuesSetting: '標記錯誤',
      highlightIssuesSettingDescription: '錯誤以黃色標記',
      interfaceLanguageSetting: '界面語言',
      interfaceLanguageSettingDescription: '以所選語言顯示應用程序菜單',
      backtranslationLanguageSetting: '翻譯語言',
      backtranslationLanguageSettingDescription: '更正的英語翻譯成所選的語言',
      lowProficiencyDescription: '我的英語很难懂',
      mediumProficiencyDescription: '英語不是我的母語，但人們一般可以聽得懂',
      highProficiencyDescription: '我英語流利，但偶爾會出現語法錯誤',
      aiSystemProficiencyInfo:
        '我們的AI系統在生成更正時會考慮您的英語水平。 如果您認為系統不足或過度，請嘗試其他級別。',
      proficiencySettingShort: '熟練程度',
      proficiencySettingLong: '英語能力',
      appSettings: '应用設定',
      settingsNext: '下一步',
      settingsBack: '上一步',
      accountSettings: '账号設定',
      yearlySubscription: '年度訂購',
      priceFree: '免費',
      priceProfessional: '專業版',
      priceMonth: '月费',
      priceYear: '年費',
      percentOff50: '5折',
      feature1000WordsPerDay: '每天1000字',
      feature500WordDocuments: '500字文檔',
      feature1WritingStyle: '1種寫作風格',
      featureUnlimitedGrammarCorrection: '語法無限制修正',
      featureLongDocumentLength: '10000字數限制',
      featureAllWritingStyles: '所有寫作風格',
      feature1User: '當前用戶',
      subscriptionPurchased: '已購買',
      purchaseSubscription: '購買',
      resumeSubscription: '恢復訂閱',
      completePurchase: '完成購買',
      canceledSubscriptionHasAccessUntil: '您的訂閱已取消，但是您可以繼續使用Gramara Pro，直到<0>{{date}}</0>',
      subscriptionRenewsAt: '您購買的服務將設置為在<1>{{nextBillingDate}}</1>上續訂<2>${{nextBillingPeriodAmount}}</2>',
      youAreOnTheFreePlan: '您目前正在使用免費計劃',
      myAccount: '我的賬戶',
      cancelSubscription: '取消訂購',
      changeMyPassword: '修改密碼',
      changePasswordEmailSentTo: '修改密碼的電子郵件發送至 {{email}}',
      logout: '登出',
      subscriptionResumesAfter: '在{{date}}之後，您當前的訂閱到期後，您才需要付費',
      noDailyWordsRemaining: '您已达到每日字数限制',
      learnAboutGramaraPro: '了解更多',
      reachedWordLimit: '您已達到字數上限',
      wordsUsedCount: '使用 {{current }} / {{total}} 個字',
      yearlyBilledAsOnePayment: '記為$ {{price}}的一筆付款',
      checkGrammar: '驗證語法',
      retry: '重試',
    },
  },
  ja: {
    common: {
      writingStyle: '文体',
      defaultWritingStyle: 'デフォルト',
      conciseWritingStyle: '端的',
      conciseWritingStyleSubtitle: '簡潔/直接的な表現',
      formalWritingStyle: 'ビジネス',
      formalWritingStyleSubtitle: 'フォーマルな表現',
      conversationWritingStyle: '会話調',
      conversationWritingStyleSubtitle: 'フレンドリーな表現',
      writeHereToGetStarted: 'ここに英語でご入力ください',
      settings: '設定',
      account: 'アカウント',
      noCorrectionsFound: '修正が必要な箇所は見つかりませんでした。',
      applyCorrection: '修正を反映',
      highlightIssuesSetting: '問題箇所をハイライト',
      highlightIssuesSettingDescription: '問題箇所を黄色にハイライトします',
      interfaceLanguageSetting: '言語選択',
      interfaceLanguageSettingDescription: 'メニューをこの言語で表示する',
      backtranslationLanguageSetting: '翻訳',
      backtranslationLanguageSettingDescription: '修正後の文章をこの言語に逆翻訳する',
      lowProficiencyDescription: '私の英語は理解されにくいです',
      mediumProficiencyDescription: '私の英語はだいたい理解されますが、母国語ではありません。',
      highProficiencyDescription: '私は英語を問題なく話しますが、よく文法を間違えます。',
      aiSystemProficiencyInfo:
        '当サイトのAIがあなたの英語レベルを考慮して、修正を提示します。修正が足りない場合や多すぎる場合は、レベルを変えてご確認ください。',
      proficiencySettingShort: 'レベル',
      proficiencySettingLong: '英語のレベル',
      appSettings: 'アプリ設定',
      settingsNext: '次へ',
      settingsBack: '戻る',
      accountSettings: 'アカウント設定',
      yearlySubscription: '年間サブスクリプション',
      priceFree: '無料プラン',
      priceProfessional: 'プロプラン',
      priceMonth: '月',
      priceYear: '年',
      percentOff50: '50%割引',
      feature1000WordsPerDay: '1日上限1000単語',
      feature500WordDocuments: '上限500単語の文書',
      feature1WritingStyle: '1文体限定',
      featureUnlimitedGrammarCorrection: '文法修正：無制限',
      featureLongDocumentLength: '上限10,000単語の文書',
      featureAllWritingStyles: '全文体使用可',
      feature1User: '1ユーザー限定',
      subscriptionPurchased: '購入が完了しました',
      purchaseSubscription: '購入',
      resumeSubscription: 'サブスクリプションを再開',
      completePurchase: '購入完了',
      canceledSubscriptionHasAccessUntil:
        'あなたのサブスクリプションはキャンセルされました。<0>{{date}}</0>まではGramaraプロプランをご利用いただけます',
      subscriptionRenewsAt:
        'あなたのサブスクリプションは、<1>{{nextBillingDate}}</1> に更新されます。金額は、<2>${{nextBillingPeriodAmount}}</2>になります。',
      youAreOnTheFreePlan: '現在無料プランをご利用中です。',
      myAccount: 'マイアカウント',
      cancelSubscription: 'サブスクリプションをキャンセルする',
      changeMyPassword: 'パスワードを変更',
      changePasswordEmailSentTo: '{{email}}にメールが送られました',
      logout: 'ログアウト',
      subscriptionResumesAfter: '現在のサブスクリプションが完了する{{date}}に請求が行われます。',
      noDailyWordsRemaining: '1日の上限単語数に達しました。',
      learnAboutGramaraPro: '詳しくはこちら',
      reachedWordLimit: '（1日の）上限単語数に達しました。',
      wordsUsedCount: '{{current}} / {{total}} 単語分 利用されています。',
      yearlyBilledAsOnePayment: '一括払いで、${{price} 請求されます。}',
      checkGrammar: '文法チェックを行う',
      retry: 'リトライ',
    },
  },
  fr: {
    common: {
      writingStyle: "Style d'écriture",
      defaultWritingStyle: 'Par défaut',
      conciseWritingStyle: 'Concis',
      conciseWritingStyleSubtitle: 'Bref et direct',
      formalWritingStyle: 'Document professionnel',
      formalWritingStyleSubtitle: 'Ton formel',
      conversationWritingStyle: 'Conversation',
      conversationWritingStyleSubtitle: 'Ton informel et amical',
      writeHereToGetStarted: 'Pour commencer, écrivez ici quelque chose en anglais',
      settings: 'Paramètres',
      account: 'Ton',
      noCorrectionsFound: 'Aucune correction trouvée',
      applyCorrection: 'Appliquer',
      highlightIssuesSetting: 'Surligner les problèmes potentiels',
      highlightIssuesSettingDescription: 'Les problèmes potentiels sont surlignés en jaune',
      interfaceLanguageSetting: "Langue de l'interface",
      interfaceLanguageSettingDescription: "Afficher le menu de l'appli en cette langue",
      backtranslationLanguageSetting: 'Langue de la traduction',
      backtranslationLanguageSettingDescription: 'Traduire les phrases en anglais corrigé vers cette langue',
      lowProficiencyDescription: 'Les gens ont du mal à comprendre mon anglais.',
      mediumProficiencyDescription:
        "Les gens comprennent généralement mon anglais, mais ce n'est clairement pas ma langue maternelle.",
      highProficiencyDescription: 'Je parle anglais couramment, mais je fais parfois quelques erreurs grammaticales.',
      aiSystemProficiencyInfo:
        "Notre intelligence artificielle prend en compte votre maîtrise de l'anglais pour générer ses corrections. Essayez un niveau différent si vous trouvez que le système vous corrige trop ou pas assez.",
      proficiencySettingShort: 'Niveau',
      proficiencySettingLong: "Maîtrise de l'anglais",
      appSettings: 'Application',
      settingsNext: 'Suivant',
      settingsBack: 'Précédent',
      accountSettings: 'Paramètres du compte',
      yearlySubscription: 'Abonnement annuel',
      priceFree: 'Gratuit',
      priceProfessional: 'Professionnel',
      priceMonth: 'mois',
      priceYear: 'an',
      percentOff50: '-50%',
      feature1000WordsPerDay: '1000 mots par jour',
      feature500WordDocuments: '500 mots max par document',
      feature1WritingStyle: "1 style d'écriture",
      featureUnlimitedGrammarCorrection: 'Corrections grammaticales illimitées',
      featureLongDocumentLength: "Documents jusqu'à 10.000 mots",
      featureAllWritingStyles: "Tous styles d'écriture débloqués",
      feature1User: '1 seul utilisateur',
      subscriptionPurchased: 'Acheté',
      purchaseSubscription: 'Acheter',
      resumeSubscription: "Reprendre l'abonnement",
      completePurchase: "Finaliser l'achat",
      canceledSubscriptionHasAccessUntil:
        "Votre abonnement est annulé, mais vous continuerez à pouvoir utiliser Gramara Pro jusqu'au <0>{{date}}</0>",
      subscriptionRenewsAt:
        'Il est prévu que votre abonnement soit renouvelé le <1>{{nextBillingDate}}</1> pour <2>{{nextBillingPeriodAmount}} $</2>',
      youAreOnTheFreePlan: 'Vous utilisez actuellement la version gratuite',
      myAccount: 'Mon compte ',
      cancelSubscription: "Annuler l'abonnement",
      changeMyPassword: 'Changer mon mot de passe',
      changePasswordEmailSentTo: 'Courriel envoyé à {{email}}',
      logout: 'Déconnexion',
      subscriptionResumesAfter: 'Vous ne serez pas facturé avant la fin de votre abonnement actuel le {{date}}',
      noDailyWordsRemaining: 'Vous avez atteint votre limite quotidienne de mots.',
      learnAboutGramaraPro: 'En savoir plus',
      reachedWordLimit: 'Vous avez atteint la limite de mots',
      wordsUsedCount: '{{current}} / {{total}} mots utilisés',
      yearlyBilledAsOnePayment: 'Facturé en un paiement unique de {{price}}  ',
      checkGrammar: 'Vérifier la grammaire',
      retry: 'Recommencez',
    },
  },
  de: {
    common: {
      writingStyle: 'Schreibstil',
      defaultWritingStyle: 'Standardeinstellung',
      conciseWritingStyle: 'Prägnant',
      conciseWritingStyleSubtitle: 'kurzgefasst, direkt',
      formalWritingStyle: 'Berufliches Dokument',
      formalWritingStyleSubtitle: 'Formeller Tonfall',
      conversationWritingStyle: 'Konversation',
      conversationWritingStyleSubtitle: 'Informeller, freundlicher Tonfall',
      writeHereToGetStarted: 'Schreiben Sie hier etwas auf Englisch um loszulegen',
      settings: 'Einstellungen',
      account: 'Account',
      noCorrectionsFound: 'Keine Verbesserungen gefunden',
      applyCorrection: 'Anwenden',
      highlightIssuesSetting: 'Mögliche Probleme anzeigen',
      highlightIssuesSettingDescription: 'Mögliche Probleme sind gelb markiert',
      interfaceLanguageSetting: 'Interface Sprache',
      interfaceLanguageSettingDescription: 'App Menü in dieser Sprache anzeigen',
      backtranslationLanguageSetting: 'Übersetzungssprache',
      backtranslationLanguageSettingDescription: 'Lassen Sie korrigierte Sätze in diese Sprache übersetzen',
      lowProficiencyDescription: 'Es fällt den Leuten schwer, mein Englisch zu verstehen',
      mediumProficiencyDescription:
        'Die Leute verstehen mein Englisch normalerweise, aber es ist eindeutig nicht meine Erstsprache.',
      highProficiencyDescription: 'Ich spreche fließend Englisch, mache aber gelegentlich grammatikalische Fehler',
      aiSystemProficiencyInfo:
        'Unser AI System berücksichtigt Ihre Englischkenntnisse bei der Generierung von Verbesserungen. Probieren Sie ein anderes Level aus, falls Sie das Gefühl haben, dass das System zu viel oder zu wenig korrigiert.',
      proficiencySettingShort: 'Kenntnisse',
      proficiencySettingLong: 'Englisch Kenntnisse',
      appSettings: 'App Einstellungen',
      settingsNext: 'Weiter',
      settingsBack: 'Zurück',
      accountSettings: 'Account Einstellungen',
      yearlySubscription: 'Jährliches Abonnement',
      priceFree: 'Kostenlos',
      priceProfessional: 'Professional',
      priceMonth: 'Monat',
      priceYear: 'Jahr',
      percentOff50: '50% off',
      feature1000WordsPerDay: '1000 Worte pro Tag',
      feature500WordDocuments: '500 Wort Dokumente',
      feature1WritingStyle: '1 Schreibstil',
      featureUnlimitedGrammarCorrection: 'Unbegrenzte Grammatikkorrekturen',
      featureLongDocumentLength: '10,000 Wortlimit',
      featureAllWritingStyles: 'Schalten Sie alle Schreibstile frei',
      feature1User: '1 Nutzer',
      subscriptionPurchased: 'Erworben',
      purchaseSubscription: 'Kaufen',
      resumeSubscription: 'Abonnement fortsetzen',
      completePurchase: 'Kauf abschließen',
      canceledSubscriptionHasAccessUntil:
        'Ihr Abonnement wurde gekündigt, Sie haben jedoch weiterhin Zugriff auf Gramara Pro bis zum <0>{{date}}</0>',
      subscriptionRenewsAt:
        'Ihr Abonnement verlängert sich am <1>{{nextBillingDate}}</1> for <2>${{nextBillingPeriodAmount}}</2>',
      youAreOnTheFreePlan: 'Sie nutzen momentan den kostenlosen Plan',
      myAccount: 'Mein Account',
      cancelSubscription: 'Abonnement kündigen',
      changeMyPassword: 'Mein Passwort ändern',
      changePasswordEmailSentTo: 'E-Mail versendet an {{email}}',
      logout: 'Ausloggen',
      subscriptionResumesAfter:
        'Es werden Ihnen vor dem Ende Ihres aktuellen Abonnements am {{date}} keine Kosten berechnet.',
      noDailyWordsRemaining: 'Sie haben Ihr tägliches Wortlimit erreicht.',
      learnAboutGramaraPro: 'Mehr erfahren',
      reachedWordLimit: 'Sie haben das Wortlimit erreicht',
      wordsUsedCount: '{{current}} / {{total}} Worte verwendet',
      yearlyBilledAsOnePayment: 'Abgerechnet als eine einmalige Zahlung in Höhe von €{{price}}',
      checkGrammar: 'Grammatik überprüfen',
      retry: 'Wiederholen',
    },
  },
  ru: {
    common: {
      writingStyle: 'Стиль речи',
      defaultWritingStyle: 'Стандартный',
      conciseWritingStyle: 'Сжатый',
      conciseWritingStyleSubtitle: 'Краткий и прямой',
      formalWritingStyle: 'Деловой',
      formalWritingStyleSubtitle: 'Официальный тон',
      conversationWritingStyle: 'Разговорный',
      conversationWritingStyleSubtitle: 'Дружеский тон',
      writeHereToGetStarted: 'Чтобы приступить, напишите что-нибудь на английском языке',
      settings: 'Настройки',
      account: 'Профиль',
      noCorrectionsFound: 'Не требует исправлений',
      applyCorrection: 'Применить',
      highlightIssuesSetting: 'Подсвечивать проблемные места',
      highlightIssuesSettingDescription: 'Спорные места будут подсвечиваться желтым цветом',
      interfaceLanguageSetting: 'Язык интерфейса',
      interfaceLanguageSettingDescription: 'Новый язык применится для всего меню',
      backtranslationLanguageSetting: 'Язык перевода',
      backtranslationLanguageSettingDescription: 'Перевод исправленного текста на выбранный язык',
      lowProficiencyDescription: 'Люди плохо понимают мой английский',
      mediumProficiencyDescription: 'Обычно мой английский понимают, но это явно не мой родной язык',
      highProficiencyDescription: 'Я бегло говорю по-английски, но иногда допускаю ошибки',
      aiSystemProficiencyInfo:
        'Система с искусственным интеллектом принимает во внимание Ваш уровень английского при генерации исправлений. Если Вам кажется, что система делает недостаточное или, наоборот, избыточное количество исправлений, поменяйте уровень владения языком',
      proficiencySettingShort: 'Уровень языка',
      proficiencySettingLong: 'Уровень владения английским',
      appSettings: 'Настройки приложения',
      settingsNext: 'Дальше',
      settingsBack: 'назад',
      accountSettings: 'Настройки профиля',
      yearlySubscription: 'Годовая подписка',
      priceFree: 'Бесплатная',
      priceProfessional: 'Профессиональная',
      priceMonth: 'в месяц',
      priceYear: 'в год',
      percentOff50: 'скидка 50%',
      feature1000WordsPerDay: '1000 слов в день',
      feature500WordDocuments: 'Документы до 500 слов',
      feature1WritingStyle: '1 стиль речи',
      featureUnlimitedGrammarCorrection: 'Проверка без ограничений',
      featureLongDocumentLength: 'Лимит в 10 000 слов',
      featureAllWritingStyles: 'Открыты все стили речи',
      feature1User: '1 пользователь',
      subscriptionPurchased: 'Куплено',
      purchaseSubscription: 'Купить',
      resumeSubscription: 'Продлить подписку',
      completePurchase: 'Завершить покупку',
      canceledSubscriptionHasAccessUntil: 'Подписка отменена, но доступ к Gramara Pro будет активен до <0>{{date}}</0>',
      subscriptionRenewsAt: 'Подписка будет продлена <1>{{nextBillingDate}}</1> за <2>${{nextBillingPeriodAmount}}</2>',
      youAreOnTheFreePlan: 'В текущий момент активен бесплатный доступ',
      myAccount: 'Мой профиль',
      cancelSubscription: 'Отменить подписку',
      changeMyPassword: 'Изменить пароль',
      changePasswordEmailSentTo: 'На адрес {{email}} было отправлено письмо',
      logout: 'Выйти',
      subscriptionResumesAfter: 'Плата не будет взиматься до конца периода подписки {{date}}',
      noDailyWordsRemaining: 'Вы достигли дневного лимита слов',
      learnAboutGramaraPro: 'Узнать больше',
      reachedWordLimit: 'Вы достигли лимита слов',
      wordsUsedCount: '{{current}} / {{total}} слов проверено',
      yearlyBilledAsOnePayment: 'Взимается единоразовая плата ${{price}}',
      checkGrammar: 'Поиск ошибок',
      retry: 'повторная попытка',
    },
  },
  uk: {
    common: {
      writingStyle: 'Стиль мовлення',
      defaultWritingStyle: 'Стандартний',
      conciseWritingStyle: 'Стислий',
      conciseWritingStyleSubtitle: 'Прямий та зрозумілий',
      formalWritingStyle: 'Діловий',
      formalWritingStyleSubtitle: 'Офіційний тон',
      conversationWritingStyle: 'Розмовний',
      conversationWritingStyleSubtitle: 'Дружній тон',
      writeHereToGetStarted: 'Щоб почати, напишіть що-небудь англійською',
      settings: 'Налаштування',
      account: 'Профіль',
      noCorrectionsFound: 'Не потребує виправлень',
      applyCorrection: 'Застосувати',
      highlightIssuesSetting: 'Виділяти проблемні місця',
      highlightIssuesSettingDescription: 'Спірні місця підсвічуватимуться жовтим кольором',
      interfaceLanguageSetting: 'Мова інтерфейсу',
      interfaceLanguageSettingDescription: 'Нова мова застосується для всього меню',
      backtranslationLanguageSetting: 'Мова перекладу',
      backtranslationLanguageSettingDescription: 'Переклад виправленого тексту на обрану мову',
      lowProficiencyDescription: 'Люди погано розуміють мою англійську',
      mediumProficiencyDescription: 'Зазвичай мою англійську розуміють, але це явно не моя рідна мова',
      highProficiencyDescription: 'Я добре розмовляю англійською, але іноді допускаю помилки',
      aiSystemProficiencyInfo:
        'Система зі штучним інтелектом бере до уваги Ваш рівень англійської при генерації виправлень. Якщо Вам здається, що система робить недостатньо або, навпаки, забагато виправлень, поміняйте рівень володіння мовою',
      proficiencySettingShort: 'Рівень мови',
      proficiencySettingLong: 'Рівень володіння англійською',
      appSettings: 'Налаштування програми',
      settingsNext: 'Далі',
      settingsBack: 'назад',
      accountSettings: 'Налаштування профілю',
      yearlySubscription: 'Річна підписка',
      priceFree: 'Безкоштовна',
      priceProfessional: 'Професійна',
      priceMonth: 'на місяць',
      priceYear: 'на рік',
      percentOff50: 'знижка 50%',
      feature1000WordsPerDay: '1000 слів на день',
      feature500WordDocuments: 'Документи до 500 слів',
      feature1WritingStyle: '1 стиль мовлення',
      featureUnlimitedGrammarCorrection: 'Перевірка без обмежень',
      featureLongDocumentLength: 'Ліміт у 10 000 слів',
      featureAllWritingStyles: 'Доступні всі стилі мовлення',
      feature1User: '1 користувач',
      subscriptionPurchased: 'Куплено',
      purchaseSubscription: 'Купити',
      resumeSubscription: 'Продовжити підписку',
      completePurchase: 'Завершити покупку',
      canceledSubscriptionHasAccessUntil:
        'Підписка скасована, але доступ до Gramara Pro буде активний до <0>{{date}}</0>',
      subscriptionRenewsAt:
        'Підписку буде продовжено <1>{{nextBillingDate}}</1> за <2>${{nextBillingPeriodAmount}}</2>',
      youAreOnTheFreePlan: 'На даний момент активний безкоштовний доступ',
      myAccount: 'Мій профіль',
      cancelSubscription: 'Скасувати підписку',
      changeMyPassword: 'Змінити пароль',
      changePasswordEmailSentTo: 'На адресу {{email}} був відправлений лист',
      logout: 'Вийти',
      subscriptionResumesAfter: 'Плата не стягуватиметься до кінця періоду підписки {{date}}',
      noDailyWordsRemaining: 'Ви досягли денного ліміту слів',
      learnAboutGramaraPro: 'Дізнатися більше',
      reachedWordLimit: 'Ви досягли ліміту слів',
      wordsUsedCount: '{{current}} / {{total}} слів перевірено',
      yearlyBilledAsOnePayment: 'Стягується одноразова плата ${{price}}',
      checkGrammar: 'Пошук помилок',
      retry: 'повторити спробу',
    },
  },
  pt: {
    common: {
      writingStyle: 'Estilo de escrita',
      defaultWritingStyle: 'Pedrão',
      conciseWritingStyle: 'Resumido',
      conciseWritingStyleSubtitle: 'Curto, direto',
      formalWritingStyle: 'Documento profissional',
      formalWritingStyleSubtitle: 'Tom formal',
      conversationWritingStyle: 'Conversacional',
      conversationWritingStyleSubtitle: 'Tom informal e amigável',
      writeHereToGetStarted: 'Para começar, escreva algo aqui',
      settings: 'Configurações',
      account: 'Conta',
      noCorrectionsFound: 'Nenhuma correção a sugerir',
      applyCorrection: 'Aplicar',
      highlightIssuesSetting: 'Destacar possíveis problemas',
      highlightIssuesSettingDescription: 'Possíveis problemas estão destacados em amarelo',
      interfaceLanguageSetting: 'Idioma da interface',
      interfaceLanguageSettingDescription: 'Exibir o menu do app neste idioma',
      backtranslationLanguageSetting: 'Idioma de tradução',
      backtranslationLanguageSettingDescription: 'Traduzir frases corrigidas para este idioma',
      lowProficiencyDescription: 'As pessoas têm dificuldade para entender meu inglês',
      mediumProficiencyDescription:
        'As pessoas costumam entender meu inglês, mas ele claramente não é meu primeiro idioma.',
      highProficiencyDescription: 'Sou fluente em inglês, mas ocasionalmente cometo erros de gramática',
      aiSystemProficiencyInfo:
        'A IA do nosso sistema leva o nível de proficiência do seu inglês em consideração ao sugerir as correções. Experimente um nível diferente caso você ache que o sistema está fazendo correções erradas ou insuficientes.',
      proficiencySettingShort: 'Proficiência',
      proficiencySettingLong: 'Nível de proficiência em inglês',
      appSettings: 'Configurações do app',
      settingsNext: 'Seguinte',
      settingsBack: 'Voltar',
      accountSettings: 'Configurações da conta',
      yearlySubscription: 'Plano anual',
      priceFree: 'Plano gratuito',
      priceProfessional: 'Plano profissional',
      priceMonth: 'mês',
      priceYear: 'ano',
      percentOff50: '50% de desconto',
      feature1000WordsPerDay: '1.000 palavras por dia',
      feature500WordDocuments: 'Documentos de até 500 palavras',
      feature1WritingStyle: '1 estilo de escrita',
      featureUnlimitedGrammarCorrection: 'Correção de gramática ilimitada',
      featureLongDocumentLength: 'Limite de 10.000 palavras',
      featureAllWritingStyles: 'Desbloqueie todos os estilos de escrita',
      feature1User: '1 usuário',
      subscriptionPurchased: 'Adquirido',
      purchaseSubscription: 'Adquirir',
      resumeSubscription: 'Retomar assinatura',
      completePurchase: 'Concluir compra',
      canceledSubscriptionHasAccessUntil:
        'Sua assinatura foi cancelada, mas você continuará tendo acesso ao Gramara Pro até <0>{{date}}</0>',
      subscriptionRenewsAt:
        'Sua assinatura será renovada em <1>{{nextBillingDate}}</1> por <2>${{nextBillingPeriodAmount}}</2>',
      youAreOnTheFreePlan: 'No momento, você está usando o plano gratuito',
      myAccount: 'Minha conta',
      cancelSubscription: 'Cancelar assinatura',
      changeMyPassword: 'Mudar minha senha',
      changePasswordEmailSentTo: 'E-mail enviado para {{email}}',
      logout: 'Sair',
      subscriptionResumesAfter: 'Você não receberá uma cobrança até sua assinatura atual terminar depois de {{date}}',
      noDailyWordsRemaining: 'Você atingiu seu limite diário de palavras.',
      learnAboutGramaraPro: 'Saiba mais',
      reachedWordLimit: 'Você atingiu o limite de palavras',
      wordsUsedCount: '{{current}} / {{total}} palavras usadas',
      yearlyBilledAsOnePayment: 'Pagamento único de ${{price}}',
      checkGrammar: 'Corrigir gramática',
      retry: 'tentar novamente',
    },
  },
  es: {
    common: {
      writingStyle: 'Estilo de escritura',
      defaultWritingStyle: 'Predeterminado',
      conciseWritingStyle: 'Conciso',
      conciseWritingStyleSubtitle: 'Breve y directo',
      formalWritingStyle: 'Documento profesional',
      formalWritingStyleSubtitle: 'Registro formal',
      conversationWritingStyle: 'Conversación',
      conversationWritingStyleSubtitle: 'Registro informal',
      writeHereToGetStarted: 'Escribe algo en inglés aquí',
      settings: 'Ajustes',
      account: 'Cuenta',
      noCorrectionsFound: 'No se han encontrado errores',
      applyCorrection: 'Aplicar',
      highlightIssuesSetting: 'Resaltar los posibles errores',
      highlightIssuesSettingDescription: 'Los posibles errores se resaltarán en amarillo',
      interfaceLanguageSetting: 'Idioma de la interfaz',
      interfaceLanguageSettingDescription: 'El menú de la aplicación se mostrará en este idioma',
      backtranslationLanguageSetting: 'Idioma de la traducción',
      backtranslationLanguageSettingDescription: 'Traduce las frases corregidas a este idioma',
      lowProficiencyDescription: 'A la gente le cuesta entender lo que escribo en inglés.',
      mediumProficiencyDescription:
        'La gente suele entender lo que escribo en inglés, pero se nota que no es mi lengua materna.',
      highProficiencyDescription: 'Tengo un buen nivel de inglés, pero a veces cometo errores gramaticales.',
      aiSystemProficiencyInfo:
        'Nuestro sistema de IA tiene en cuenta tu nivel de inglés al hacer las correcciones. Cambia de nivel si crees que el sistema no te corrige lo suficiente o te corrige en exceso.',
      proficiencySettingShort: 'Nivel',
      proficiencySettingLong: 'Nivel de inglés',
      appSettings: 'Ajustes de la aplicación',
      settingsNext: 'Siguiente',
      settingsBack: 'Volver',
      accountSettings: 'Ajustes de la cuenta',
      yearlySubscription: 'Suscripción anual',
      priceFree: 'Gratis',
      priceProfessional: 'Profesional',
      priceMonth: 'mes',
      priceYear: 'año',
      percentOff50: '50 % de descuento',
      feature1000WordsPerDay: '1000 palabras al día',
      feature500WordDocuments: 'documentos de 500 palabras',
      feature1WritingStyle: '1 estilo de escritura',
      featureUnlimitedGrammarCorrection: 'Correcciones gramaticales ilimitadas',
      featureLongDocumentLength: 'Límite de 10 000 palabras',
      featureAllWritingStyles: 'Todos los estilos de escritura',
      feature1User: '1 usuario',
      subscriptionPurchased: 'Plan adquirido',
      purchaseSubscription: 'Suscribirme',
      resumeSubscription: 'Continuar con la suscripción',
      completePurchase: 'Finalizar compra',
      canceledSubscriptionHasAccessUntil:
        'Se ha cancelado la suscripción, pero seguirás teniendo acceso a Gramara Pro hasta el <0> {{date}}</0>',
      subscriptionRenewsAt:
        'La suscripción se renovará el <1>{{nextBillingDate}}</1> por <2>{{nextBillingPeriodAmount}} $</2>',
      youAreOnTheFreePlan: 'Actualmente tienes el plan gratuito',
      myAccount: 'Mi cuenta',
      cancelSubscription: 'Cancelar suscripción',
      changeMyPassword: 'Cambiar la contraseña',
      changePasswordEmailSentTo: 'Correo enviado a {{email}}',
      logout: 'Cerrar sesión',
      subscriptionResumesAfter: 'No se te cobrará hasta que termine la suscripción actual (el {{date}})',
      noDailyWordsRemaining: 'Has alcanzado el límite de palabras diario.',
      learnAboutGramaraPro: 'Más información',
      reachedWordLimit: 'Has alcanzado el límite de palabras',
      wordsUsedCount: 'Has utilizado {{current}} de las {{total}} palabras disponibles',
      yearlyBilledAsOnePayment: 'Se cobra en un pago de {{price}} $',
      checkGrammar: 'Revisar gramática',
      retry: 'procesar de nuevo',
    },
  },
};
