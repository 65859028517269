import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/styles';
import MaterialMenu from '@material-ui/core/Menu';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import MaterialListItemText from '@material-ui/core/ListItemText';
import MaterialListItemIcon from '@material-ui/core/ListItemIcon';
import setting from '../icons/settingwhite.svg';
import acc from '../icons/acc.svg';
import pen from '../icons/penwhite.svg';
import '../styles/Nav.css';
import { showSettingsModal, setWritingStyle, showAccountModal } from '../actions';
import Settings from '../Settings';
import Account from '../Account';

import { WritingStyles } from '../constants';

import defaultWritingStyleBlue from './../icons/defaultblue.svg';
import defaultWritingStyleWhite from './../icons/defaultwhite.svg';
import defaultWritingStyleGray from './../icons/defaultgray.svg';

import conciseWritingStyleBlue from './../icons/conciseblue.svg';
import conciseWritingStyleWhite from './../icons/concisewhite.svg';
import conciseWritingStyleGray from './../icons/concisegray.svg';

import informalWritingStyleBlue from './../icons/informalblue.svg';
import informalWritingStyleWhite from './../icons/informalwhite.svg';
import informalWritingStyleGray from './../icons/informalgray.svg';

import formalWritingStyleBlue from './../icons/formalblue.svg';
import formalWritingStyleWhite from './../icons/formalwhite.svg';
import formalWritingStyleGray from './../icons/formalgray.svg';
import { useTranslation } from 'react-i18next';

const StyledMaterialMenu = withStyles({
  paper: {
    boxShadow: '5px 6px 42px -16px rgba(0,0,0,0.2)',
    border: '1px solid white',
  },
})((props) => (
  <MaterialMenu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const StyledMaterialMenuItem = withStyles((theme) => ({
  root: {
    marginTop: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    lineHeight: '1rem',
    alignItems: 'flex-start',
    '&:focus, &:hover': {
      backgroundColor: 'unset',
    },
    '& .blue, & .white': {
      display: 'none',
    },
    '& .gray': {
      display: 'block',
    },
    '&:focus, &:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#334cff',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary, & .MuiListItemText-secondary': {
        color: 'white',
      },
      '& .gray, & .blue, & .active': {
        display: 'none',
      },
      '& .white': {
        display: 'block',
      },
    },
    '& .MuiListItemText-primary': {
      fontSize: '1.125rem',
      color: '#747d93',
      lineHeight: 'normal',
    },
    '.MuiListItemText-primary': {
      color: '#334cff',
    },
    '& .MuiListItemText-secondary': {
      fontSize: '0.9rem',
      color: '#bdc4db',
      lineHeight: 'normal',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '2.25rem',
      height: '1rem',
      marginTop: 6,
    },
  },
}))(MaterialMenuItem);

const Menu = ({ open }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const writingStyle = useSelector((state) => state.settings.writingStyle);
  const subscription = useSelector((state) => state.settings.subscription);
  const { t } = useTranslation();
  const freePlan = !subscription;

  const openLanguageMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onSelectWritingStyle = (writingStyle) => {
    if (writingStyle !== WritingStyles.DEFAULT && !subscription) {
      return;
    }

    dispatch(setWritingStyle(writingStyle));
    closeWritingStylesMewnu();
  };

  const closeWritingStylesMewnu = () => {
    setAnchorEl(null);
  };

  return (
    <nav className={classNames('nav', { open: open })}>
      <div className="button" aria-controls="writing-style-nav-menu" aria-haspopup="true" onClick={openLanguageMenu}>
        <img src={pen} alt="" /> {t('common:writingStyle')}
      </div>

      <StyledMaterialMenu
        disableAutoFocusItem
        keepMounted
        id="writing-style-nav-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeWritingStylesMewnu}
      >
        <StyledMaterialMenuItem
          selected={writingStyle === WritingStyles.DEFAULT}
          onClick={() => onSelectWritingStyle(WritingStyles.DEFAULT)}
        >
          <MaterialListItemIcon>
            <img className="blue" src={defaultWritingStyleBlue} alt="" width="20px" height="20px" />
            <img className="white" src={defaultWritingStyleWhite} alt="" width="20px" height="20px" />
            <img className="gray" src={defaultWritingStyleGray} alt="" width="20px" height="20px" />
          </MaterialListItemIcon>
          <MaterialListItemText primary={t('common:defaultWritingStyle')} />
        </StyledMaterialMenuItem>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="inline-divider" />
          <div
            style={{
              flex: 2,
              textAlign: 'center',
              padding: '14px 11px',
              fontSize: '1rem',
              color: '#747d93',
            }}
          >
            Gramara Pro
          </div>
          <div className="inline-divider" />
        </div>
        <StyledMaterialMenuItem
          disabled={freePlan}
          selected={writingStyle === WritingStyles.CONCISE}
          onClick={() => onSelectWritingStyle(WritingStyles.CONCISE)}
        >
          <MaterialListItemIcon>
            <img
              className="blue"
              src={conciseWritingStyleBlue}
              alt=""
              width="22px"
              height="18px"
              style={{ marginTop: 4 }}
            />
            <img
              className="white"
              src={conciseWritingStyleWhite}
              alt=""
              width="22px"
              height="18px"
              style={{ marginTop: 4 }}
            />
            <img
              className="gray"
              src={conciseWritingStyleGray}
              alt=""
              width="22px"
              height="18px"
              style={{ marginTop: 4 }}
            />
          </MaterialListItemIcon>
          <MaterialListItemText
            primary={t('common:conciseWritingStyle')}
            secondary={t('common:conciseWritingStyleSubtitle')}
          />
        </StyledMaterialMenuItem>

        <StyledMaterialMenuItem
          disabled={freePlan}
          selected={writingStyle === WritingStyles.FORMAL}
          onClick={() => onSelectWritingStyle(WritingStyles.FORMAL)}
        >
          <MaterialListItemIcon>
            <img
              className="blue"
              src={formalWritingStyleBlue}
              alt=""
              width="22px"
              height="25px"
              style={{ marginTop: 1 }}
            />
            <img
              className="white"
              src={formalWritingStyleWhite}
              alt=""
              width="22px"
              height="25px"
              style={{ marginTop: 1 }}
            />
            <img
              className="gray"
              src={formalWritingStyleGray}
              alt=""
              width="22px"
              height="25px"
              style={{ marginTop: 1 }}
            />
          </MaterialListItemIcon>
          <MaterialListItemText
            primary={t('common:formalWritingStyle')}
            secondary={t('common:formalWritingStyleSubtitle')}
          />
        </StyledMaterialMenuItem>
        <StyledMaterialMenuItem
          disabled={freePlan}
          selected={writingStyle === WritingStyles.INFORMAL}
          onClick={() => onSelectWritingStyle(WritingStyles.INFORMAL)}
        >
          <MaterialListItemIcon>
            <img className="blue" src={informalWritingStyleBlue} alt="" width="23px" height="24px" />
            <img className="white" src={informalWritingStyleWhite} alt="" width="23px" height="24px" />
            <img className="gray" src={informalWritingStyleGray} alt="" width="23px" height="24px" />
          </MaterialListItemIcon>
          <MaterialListItemText
            primary={t('common:conversationWritingStyle')}
            secondary={t('common:conversationWritingStyleSubtitle')}
          />
        </StyledMaterialMenuItem>
      </StyledMaterialMenu>

      <div className={'button'} onClick={() => dispatch(showSettingsModal())}>
        <img src={setting} alt="" /> {t('common:settings')}
      </div>

      <div className={'button'} onClick={() => dispatch(showAccountModal())}>
        <img src={acc} alt="" /> {t('common:account')}
      </div>
    </nav>
  );
};

const Burger = ({ open, setOpen }) => {
  return (
    <button className={classNames('burger', { open: open })} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </button>
  );
};

const Nav = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  return (
    <div ref={node}>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
      <Settings />
      <Account />
    </div>
  );
};

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

export default Nav;
