import { useMediaQuery } from 'react-responsive';

export default function() {
  const notTabletOrMobile = useMediaQuery({ query: '(min-width: 769px)' });

  if (!window.$crisp && notTabletOrMobile) {
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '60a9ecb4-9fa6-4262-8e79-74aaae4f1cf9';

    (function() {
      var d = document;
      var s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }

  return null;
}
