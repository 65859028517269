import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import MaterialButton from '@material-ui/core/Button';
import MaterialIconButton from '@material-ui/core/IconButton';
import closeIcon from './../icons/close.svg';
import backArrowBlueIcon from './../icons/backarrowblue.svg';
import { hideSettingsModal } from '../actions';
import Proficiency from './Proficiency';
import AppSettings from './AppSettings';

import '../styles/Settings.css';
import { useTranslation } from 'react-i18next';

export default () => {
  const [currentTab, setCurrentTab] = useState("proficiency");
  const settingsModalVisible = useSelector(state => state.settings.settingsModalVisible)
  const proficiency = useSelector(state => state.settings.proficiency)
  const showCloseButton = useSelector(state => state.settings.modalProps.showCloseButton)
  const showNextButton = useSelector(state => state.settings.modalProps.showNextButton)
  const dispatch = useDispatch()
  const { t } = useTranslation();

  if (!settingsModalVisible) {
    return null;
  }

  return (
    <div className="settings" style={{ backgroundColor: 'rgba(51,76,255, 0.7)' }}>
      <div className="card">
        <div className="header-tabs">
          {showCloseButton && <div className="back-button" onClick={() => dispatch(hideSettingsModal())}>
            <img src={backArrowBlueIcon} className="back-arrow" alt="" />
            <span className="back-text">{t("common:settingsBack")}</span>
          </div>}
          <h2 className={classNames({ selected: currentTab === "proficiency" })} onClick={() => setCurrentTab("proficiency")}>
            <span className="desktop-only">{t("common:proficiencySettingLong")}</span>
            <span className="mobile-only">{t("common:proficiencySettingShort")}</span>
          </h2>
          <h2 className={classNames({ selected: currentTab === "appSettings" })} onClick={() => setCurrentTab("appSettings")}>
            {t("common:appSettings")}
          </h2>
        </div>
        {showCloseButton && <MaterialIconButton className="close-btn" onClick={() => dispatch(hideSettingsModal())} >
          <img className="close-icon" src={closeIcon} alt="" />
        </MaterialIconButton>}
        {currentTab === "proficiency" && <Proficiency />}
        {currentTab === "appSettings" && <AppSettings />}
        {showNextButton &&
          <div className="action">
            <MaterialButton
              variant="contained"
              disabled={!proficiency}
              color="primary"
              className="active"
              onClick={() => dispatch(hideSettingsModal())}
            >
            {t("common:settingsNext")}
          </MaterialButton>
          </div>
        }
      </div>
    </div>
  );
};
