import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { diffWordsWithSpace } from 'diff';
import MediaQuery from 'react-responsive';
import Right from './Right';
import RightMobile from './RightMobile';
import axios from 'axios';

class Corrections extends Component {
  state = {
    translation: null,
  };
  translationCache = {};

  fetchTranslation(nextProps) {
    if (nextProps.corrections === 'fetching') {
      if (this.state.translation) {
        this.setState({
          translation: null,
        });
      }
      return;
    }

    if (!nextProps.corrections) {
      return;
    }

    const correction = nextProps.corrections[nextProps.active];
    if (!correction || !nextProps.translationLanguage) {
      return;
    }

    if (this.translationCache[correction]) {
      this.setState({
        translation: this.translationCache[correction],
      });
      return;
    }

    axios // Get language settings
      .post('https://gcp.gramara.com/translate', {
        text: correction,
        source: 'en',
        target: nextProps.translationLanguage,
      })
      .then((resp) => {
        setTimeout(() => {
          this.translationCache[correction] = resp.data.translation;
          this.setState({
            translation: resp.data.translation,
          });
        });
      });
  }

  hasChanged(nextProps, nextState = null) {
    const propsChanged =
      JSON.stringify(this.props.corrections) !== JSON.stringify(nextProps.corrections) ||
      this.props.active !== nextProps.active ||
      (this.props.currentSegment &&
        nextProps.currentSegment &&
        this.props.currentSegment.key !== nextProps.currentSegment.key) ||
      nextProps.translationLanguage !== this.props.translationLanguage;

    const stateChanged = nextState && nextState.translation !== this.state.translation;
    return propsChanged || stateChanged;
  }

  componentWillReceiveProps(nextProps) {
    const hasChanged = this.hasChanged(nextProps);

    if (nextProps.translationLanguage !== this.props.translationLanguage) {
      this.translationCache = {};
    }

    if (hasChanged) {
      this.fetchTranslation(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.hasChanged(nextProps, nextState);
  }

  renderCorrectionNav = (index) => {
    return (
      <li
        key={index}
        onClick={() => this.props.toggleCorrectionNav(index)}
        className={classNames({ active: index === this.props.active })}
      >
        {index + 1}
      </li>
    );
  };

  replaceWord = (delta, item, key) => {
    const simplified = delta.map(({ added, removed, value }) =>
      added ? '1' : removed ? '-1' : value !== ' ' ? 0 : '+'
    );
    const beforePart = simplified.slice(0, key);
    const afterPart = simplified.slice(key + 1, delta.length);

    const from =
      beforePart.findIndex((item) => item === 0) === -1
        ? beforePart.length
        : [...beforePart].reverse().findIndex((item) => item === 0);

    const to =
      afterPart.findIndex((item) => item === 0) === -1 ? afterPart.length : afterPart.findIndex((item) => item === 0);

    const range = delta.slice(from === 0 ? key : key - from, to === 0 ? key + 1 : key + to + 1);

    const replacement = delta.reduce((str, { removed, added, value }) => {
      if (removed) {
        if (!range.find((item) => item.removed && item.value === value)) {
          return str + value;
        }
      } else if (added) {
        if (range.find((item) => item.added && item.value === value)) {
          return str + value;
        }
      } else {
        return str + value;
      }

      return str;
    }, '');

    this.props.applyWord(replacement);
  };

  getDelta = () => {
    let delta = diffWordsWithSpace(this.props.currentSegment.sentence, this.props.corrections[this.props.active]);

    const simplified = delta.map(({ added, removed, value }) =>
      added ? '1' : removed ? '-1' : value !== ' ' ? 0 : '+'
    );
    const plsIndexes = simplified
      .map((i, key) => {
        return i === '+' ? key : null;
      })
      .filter((v) => v);

    plsIndexes.forEach(() => {
      const pls = simplified.findIndex((i) => i === '+');

      if (pls === -1) {
        return;
      }
      const simplifiedRightIndex = simplified.findIndex((i, key) => (key > pls ? i === 0 : false));
      const rightIndex = simplifiedRightIndex === -1 ? simplified.length : simplifiedRightIndex;
      const simplifiedLeftIndex = [...simplified]
        .reverse()
        .findIndex((i, key) => (key > simplified.length - pls ? i === 0 : false));
      const leftIndex = simplifiedLeftIndex === -1 ? 0 : simplified.length - simplifiedLeftIndex;
      const concated = delta.slice(leftIndex, rightIndex);

      if (concated.length) {
        let concatedAdd = concated.filter(({ added, value }) => added || value === ' ');
        concatedAdd = concatedAdd.length
          ? concatedAdd.reduce((acc, item) => {
              acc.added = true;
              acc.removed = undefined;

              if (acc.value) {
                acc.value = acc.value + item.value;
              } else {
                acc.value = item.value;
              }

              return acc;
            }, {})
          : null;

        let concatedDelete = concated.filter(({ removed, value }) => removed || value === ' ');
        concatedDelete = concatedDelete.length
          ? concatedDelete.reduce((acc, item) => {
              acc.added = undefined;
              acc.removed = true;

              if (acc.value) {
                acc.value = acc.value + item.value;
              } else {
                acc.value = item.value;
              }

              return acc;
            }, {})
          : null;

        if (concatedDelete && concatedAdd) {
          delta.splice(leftIndex, rightIndex - leftIndex, concatedAdd, concatedDelete);
          simplified.splice(leftIndex, rightIndex - leftIndex, '1', '-1');
        } else if (concatedDelete) {
          delta.splice(leftIndex, rightIndex - leftIndex, concatedDelete);
          simplified.splice(leftIndex, rightIndex - leftIndex, '-1');
        } else if (concatedAdd) {
          delta.splice(leftIndex, rightIndex - leftIndex, concatedAdd);
          simplified.splice(leftIndex, rightIndex - leftIndex, '1');
        }
      }
    });

    delta = delta.filter((diff, index, arr) => {
      return !(
        diff.removed &&
        ((index > 0 && arr[index - 1].added) || (index + 1 < arr.length && arr[index + 1].added))
      );
    });

    return delta;
  };

  render() {
    return (
      <Fragment>
        <MediaQuery minWidth={769}>
          <Right
            getDelta={this.getDelta}
            currentSegment={this.props.currentSegment}
            corrections={this.props.corrections}
            translation={this.state.translation}
            active={this.props.active}
            cache={this.props.cache}
            applyText={this.props.applyText}
            replaceWord={this.replaceWord}
            renderCorrectionNav={this.renderCorrectionNav}
          />
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <RightMobile
            getDelta={this.getDelta}
            currentSegment={this.props.currentSegment}
            corrections={this.props.corrections}
            translation={this.state.translation}
            active={this.props.active}
            cache={this.props.cache}
            applyText={this.props.applyText}
            replaceWord={this.replaceWord}
            renderCorrectionNav={this.renderCorrectionNav}
            toggleCorrectionNav={this.props.toggleCorrectionNav}
          />
        </MediaQuery>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  translationLanguage: state.settings.translationLanguage,
}))(Corrections);
