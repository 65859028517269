import React, { Fragment, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Icon } from './components';
import MaterialButton from '@material-ui/core/Button';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/RightMobile.css';
import { useDispatch, useSelector } from 'react-redux';
import { showAccountModal, setCheckGrammarRequested } from '../actions';
import { useTranslation } from 'react-i18next';
import refresh from './../icons/refresh.svg';
import check from './../icons/check.svg';
import CorrectionRemovedWord from './CorrectionRemovedWord';

const renderCorrections = (props) => {
  const delta = props.getDelta();

  return delta.map((correction, key) => {
    if (correction.removed) {
      return (
        <CorrectionRemovedWord
          key={key}
          removeWord={() => props.replaceWord(delta, correction, key)}
          word={correction.value}
        />
      );
    }

    if (correction.added) {
      return (
        <span key={key} onClick={() => props.replaceWord(delta, correction, key)} className="correction-word">
          {correction.value}
        </span>
      );
    }

    return <span key={key}>{correction.value}</span>;
  });
};

export default (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dailyWordsRemaining = useSelector((state) => state.settings.dailyWordsRemaining);
  const translationLanguage = useSelector((state) => state.settings.translationLanguage);
  const errorFetchingCorrections = useSelector((state) => state.settings.errorFetchingCorrections);
  const subscription = useSelector((state) => state.settings.subscription);
  const wordLimit = subscription ? null : 1000;

  const editorChangedAt = useSelector((state) => state.settings.editorChangedAt || 0);
  const [editorLastChangedAt, setEditorLastChangedAt] = useState(editorChangedAt);

  let showCheckGrammarButton = false;
  if ((window.isAndroid && editorChangedAt > editorLastChangedAt) || errorFetchingCorrections) {
    showCheckGrammarButton = true;
  }

  const showApplyCorrectionsButton = Boolean(props.corrections.length) && typeof props.corrections !== 'string';
  const hideButtonLabels = showCheckGrammarButton && (showApplyCorrectionsButton || props.translation);
  const [showTranslation, setShowTranslation] = useState(false);

  return (
    <div className="correction-container">
      {dailyWordsRemaining <= 0 && (
        <div>
          <div>{t('common:noDailyWordsRemaining')}</div>
          <div>
            <MaterialButton variant="contained" color="primary" onClick={() => dispatch(showAccountModal())}>
              {t('common:learnAboutGramaraPro')}
            </MaterialButton>
          </div>
        </div>
      )}
      {dailyWordsRemaining > 0 && (
        <Fragment>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span
              onClick={() => {
                setEditorLastChangedAt(editorChangedAt);
                dispatch(setCheckGrammarRequested(true));
              }}
              className="correction-block-apply"
              style={{
                whiteSpace: 'nowrap',
                visibility: showCheckGrammarButton ? 'visible' : 'hidden',
                marginRight: 'auto',
              }}
            >
              <Icon>refresh</Icon>
              <span style={{ display: hideButtonLabels ? 'none' : 'inline', paddingRight: 0 }}>
                {' '}
                {errorFetchingCorrections ? t('common:retry') : t('common:checkGrammar')}
              </span>
            </span>

            {showApplyCorrectionsButton && (
              <Fragment>
                <span onClick={props.applyText} className="correction-block-apply">
                  <Icon>check</Icon>
                  <span style={{ display: hideButtonLabels ? 'none' : 'inline' }}> {t('common:applyCorrection')}</span>
                </span>

                {Boolean(translationLanguage) && (
                  <span
                    onClick={() => setShowTranslation(!showTranslation)}
                    className="correction-block-apply"
                    style={{ marginLeft: 16 }}
                  >
                    <span>{showTranslation ? 'EN' : translationLanguage.toUpperCase()}</span>
                  </span>
                )}
              </Fragment>
            )}
          </div>

          <div className="correction">
            {props.corrections === 'fetching' ? (
              <div className="correction-spinner" />
            ) : (
              <Carousel
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                selectedItem={props.active}
                onChange={(index) => props.toggleCorrectionNav(index)}
              >
                {props.corrections.length
                  ? props.corrections.map((item, key) => {
                      return (
                        <div className="correction-block" key={key}>
                          {showTranslation ? (
                            <span style={{ color: 'black' }}>{props.translation}</span>
                          ) : (
                            <span>{renderCorrections(props)}</span>
                          )}
                        </div>
                      );
                    })
                  : null}
              </Carousel>
            )}

            {!Boolean(props.corrections.length) && Boolean(props.cache.length) && (
              <div className="correction-empty">
                {Boolean(props.currentSegment) ? (
                  <Fragment>
                    <Icon>check</Icon> {t('common:noCorrectionsFound')}
                  </Fragment>
                ) : (
                  <div style={{ marginBottom: 32 }}>{t('common:clickToSeeCorrections')}</div>
                )}
              </div>
            )}
          </div>
        </Fragment>
      )}
      {Boolean(wordLimit) && Boolean(dailyWordsRemaining) && (
        <div className="corrections-count" style={{ right: 20, width: 'auto' }}>
          <div className="corrections-count-text">
            <span className="corrections-used" style={{ background: 'transparent' }}>
              {1000 - dailyWordsRemaining}
            </span>{' '}
            / 1000
          </div>
        </div>
      )}
    </div>
  );
};
