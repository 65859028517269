import * as actions from '../actions';

const initialState = {};

let user = (state = initialState, { type, uid, email, subscription, loginProviderId }) => {
  switch (type) {
    case actions.SET_USER_INFO:
      return { ...state, uid, email, subscription, loginProviderId };
    default:
      return state;
  }
};

export default user;
