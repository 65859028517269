import React, { Fragment, useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import MaterialButton from '@material-ui/core/Button';
import MaterialCircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import firebase from '../firebase';
import { cancelSubscription, logout } from '../actions';

export default () => {
  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.settings.subscription);
  const subscriptionCancelPending = useSelector((state) => state.settings.subscriptionCancelPending);
  const subscriptionCancelError = useSelector((state) => state.settings.subscriptionError);
  const user = useSelector((state) => state.user);
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="choices account-settings">
        <div>
          <div style={{ fontSize: '0.95rem', color: 'black' }}>
            {t('common:myAccount')}: {user.email}
          </div>
          {user.loginProviderId === firebase.auth.EmailAuthProvider.PROVIDER_ID && (
            <div style={{ marginTop: '0.5rem' }}>
              <MaterialButton
                variant="contained"
                color="primary"
                onClick={() => {
                  firebase.auth().sendPasswordResetEmail(user.email);
                  setResetPasswordEmailSent(true);
                }}
                disabled={resetPasswordEmailSent}
              >
                {!resetPasswordEmailSent && t('common:changeMyPassword')}
                {resetPasswordEmailSent && (
                  <Trans i18nKey="common:changePasswordEmailSentTo">{{ email: user.email }}</Trans>
                )}
              </MaterialButton>
            </div>
          )}
          {Boolean(subscription) && (
            <Fragment>
              <div style={{ fontSize: '0.95rem', color: 'black' }}>
                {subscription.status === 'Canceled' && (
                  <Trans i18nKey="common:canceledSubscriptionHasAccessUntil">
                    <strong>{{ date: subscription.nextBillingDate }}</strong>
                  </Trans>
                )}
                {subscription.status !== 'Canceled' && (
                  <Fragment>
                    <Trans i18nKey="common:subscriptionRenewsAt">
                      <strong>{{ nextBillingDate: subscription.nextBillingDate }}</strong>
                      <strong>{{ nextBillingPeriodAmount: subscription.nextBillingPeriodAmount }}</strong>
                    </Trans>
                  </Fragment>
                )}
              </div>
              {subscription.status !== 'Canceled' && (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem', marginBottom: '1rem' }}>
                  <MaterialButton
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(cancelSubscription())}
                    disabled={subscriptionCancelPending}
                  >
                    {t('common:cancelSubscription')}
                  </MaterialButton>
                  {subscriptionCancelPending && (
                    <MaterialCircularProgress size="1.75rem" style={{ marginLeft: '0.5rem' }} />
                  )}
                </div>
              )}
              {Boolean(subscriptionCancelError) && (
                <div style={{ fontSize: '0.95rem', color: 'red' }}>{subscriptionCancelError}</div>
              )}
            </Fragment>
          )}
          {!Boolean(subscription) && (
            <div style={{ fontSize: '0.95rem', color: 'black' }}>{t('common:youAreOnTheFreePlan')}</div>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '1rem' }}>
            {
              <MaterialButton variant="contained" color="primary" onClick={() => dispatch(logout())}>
                {t('common:logout')}
              </MaterialButton>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};
