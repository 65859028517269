import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import resources from './translations';

const routeLanguageDetector = {
  name: 'GramaraRouteDetector',

  lookup(options) {
    const languages = ['zh-hant', 'zh-hans', 'fr', 'ja', 'de', 'uk', 'ru', 'es', 'pt'];
    const path = window.location.pathname;

    for (let i = 0; i < languages.length; i++) {
      const lang = languages[i];
      if (path.indexOf(`/${lang}`) > -1) {
        return lang;
      }
    }

    return 'en';
  },

  cacheUserLanguage(lng, options) {
    return this.lookup(options);
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(routeLanguageDetector);

i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    detection: {
      order: [routeLanguageDetector.name],
    },
    resources,
  });

export default i18next;
