import * as actions from '../actions';

const initialState = [];

let sentences = (state = initialState, { sentence, update, index, type }) => {
  switch (type) {
    case actions.ADD_SENTENCE:
      return [...state, sentence];
    case actions.UPDATE_SENTENCE:
      return [
        ...state.slice(0, index),
        {
          ...state[index],
          ...update,
        },
        ...state.slice(index + 1),
      ];
    case actions.REMOVE_SENTENCE:
      return [...state.slice(0, index), ...state.slice(index + 1)];
    case actions.UPDATE_ALL_SENTENCES:
      return [
        ...state.map((sentence) => {
          if (sentence.isUsed !== 'boolean') {
            sentence.isUsed = false;
          }

          return sentence;
        }),
      ];
    case actions.CLEAR_CACHE:
      return initialState;
    default:
      return state;
  }
};

export default sentences;
