export const translationOptions = [
  { language: 'Afrikaans', code: 'af' },
  { language: 'Albanese', code: 'sq' },
  { language: 'አማርኛ', code: 'am' },
  { language: 'عربي', code: 'ar' },
  { language: 'հայերեն', code: 'hy' },
  { language: 'Azərbaycan', code: 'az' },
  { language: 'Euskara', code: 'eu' },
  { language: 'বাংলা', code: 'bn' },
  { language: 'Bosanski', code: 'bs' },
  { language: 'български', code: 'bg' },
  { language: 'Català', code: 'ca' },
  { language: 'Cebuano', code: 'ceb' },
  { language: '中文 - 简体', code: 'zh-CN' },
  { language: '中文 - 繁體', code: 'zh-TW' },
  { language: 'Corsican', code: 'co' },
  { language: 'Hrvatski', code: 'hr' },
  { language: 'Český', code: 'cs' },
  { language: 'Dansk', code: 'da' },
  { language: 'Nederlands', code: 'nl' },
  { language: 'Esperanto', code: 'eo' },
  { language: 'Eesti keel', code: 'et' },
  { language: 'Suomi', code: 'fi' },
  { language: 'Français', code: 'fr' },
  { language: 'Frysk', code: 'fy' },
  { language: 'Galego', code: 'gl' },
  { language: 'ქართული ენა', code: 'ka' },
  { language: 'Deutsch', code: 'de' },
  { language: 'ελληνικά', code: 'el' },
  { language: 'ગુજરાતી', code: 'gu' },
  { language: 'Kreyòl Ayisyen', code: 'ht' },
  { language: 'Hausa', code: 'ha' },
  { language: 'Ōlelo Hawaiʻi', code: 'haw' },
  { language: 'עברית', code: 'he' },
  { language: 'हिंदी', code: 'hi' },
  { language: 'Hmong', code: 'hmn' },
  { language: 'Magyar', code: 'hu' },
  { language: 'Íslenska', code: 'is' },
  { language: 'Ásụ̀sụ̀ Ìgbò', code: 'ig' },
  { language: 'bahasa Indonesia', code: 'id' },
  { language: 'Gaeilge', code: 'ga' },
  { language: 'Italiano', code: 'it' },
  { language: '日本語', code: 'ja' },
  { language: 'basa Jawa', code: 'jv' },
  { language: 'ಕನ್ನಡ', code: 'kn' },
  { language: 'Қазақ', code: 'kk' },
  { language: 'ភាសាខ្មែរ', code: 'km' },
  { language: '한국어', code: 'ko' },
  { language: 'Kurdî', code: 'ku' },
  { language: 'ລາວ', code: 'lo' },
  { language: 'Latviešu valoda', code: 'lv' },
  { language: 'Lietuvis', code: 'lt' },
  { language: 'Lëtzebuergesch', code: 'lb' },
  { language: 'Македонски', code: 'mk' },
  { language: 'Malagasy', code: 'mg' },
  { language: 'Bahasa Melayu', code: 'ms' },
  { language: 'മലയാളം', code: 'ml' },
  { language: 'Maori', code: 'mi' },
  { language: 'मराठी', code: 'mr' },
  { language: 'Монгол', code: 'mn' },
  { language: 'မြန်မာ', code: 'my' },
  { language: 'नेपाली', code: 'ne' },
  { language: 'Norsk', code: 'no' },
  { language: 'پښتو', code: 'ps' },
  { language: 'فارسی', code: 'fa' },
  { language: 'Polski', code: 'pl' },
  { language: 'Portugues', code: 'pt' },
  { language: 'ਪੰਜਾਬੀ', code: 'pa' },
  { language: 'Română', code: 'ro' },
  { language: 'русский', code: 'ru' },
  { language: 'Gagana Sāmoa', code: 'sm' },
  { language: 'Am Faclair Beag', code: 'gd' },
  { language: 'Српски', code: 'sr' },
  { language: 'Sesotho', code: 'st' },
  { language: 'Shona', code: 'sn' },
  { language: 'سنڌي', code: 'sd' },
  { language: 'සිංහල', code: 'si' },
  { language: 'Slovenský', code: 'sk' },
  { language: 'Slovenski', code: 'sl' },
  { language: 'Af-Soomaali', code: 'so' },
  { language: 'Español', code: 'es' },
  { language: 'Kiswahili', code: 'sw' },
  { language: 'Svenska', code: 'sv' },
  { language: 'Tagalog (Filipino)', code: 'tl' },
  { language: 'забо́ни тоҷикӣ́', code: 'tg' },
  { language: 'தமிழ்', code: 'ta' },
  { language: 'తెలుగు', code: 'te' },
  { language: 'ภาษาไทย', code: 'th' },
  { language: 'Türkçe', code: 'tr' },
  { language: 'Українська', code: 'uk' },
  { language: 'اردو', code: 'ur' },
  { language: "O'zbek", code: 'uz' },
  { language: 'Tiếng Việt', code: 'vi' },
  { language: 'Cymraeg', code: 'cy' },
  { language: 'isiXhosa', code: 'xh' },
  { language: 'יידיש', code: 'yi' },
  { language: 'Èdè Yorùbá', code: 'yo' },
  { language: 'IsiZulu', code: 'zu' },
];

export const codeToLanguage = {
  af: 'Afrikaans',
  sq: 'Albanese',
  am: 'አማርኛ',
  ar: 'عربي',
  hy: 'հայերեն',
  az: 'Azərbaycan',
  eu: 'Euskara',
  bn: 'বাংলা',
  bs: 'Bosanski',
  bg: 'български',
  ca: 'Català',
  ceb: 'Cebuano',
  'zh-CN': '中文 - 简体',
  'zh-TW': '中文 - 繁體',
  co: 'Corsican',
  hr: 'Hrvatski',
  cs: 'Český',
  da: 'Dansk',
  nl: 'Nederlands',
  eo: 'Esperanto',
  et: 'Eesti keel',
  fi: 'Suomi',
  fr: 'Français',
  fy: 'Frysk',
  gl: 'Galego',
  ka: 'ქართული ენა',
  de: 'Deutsch',
  el: 'ελληνικά',
  gu: 'ગુજરાતી',
  ht: 'Kreyòl Ayisyen',
  ha: 'Hausa',
  haw: 'Ōlelo Hawaiʻi',
  he: 'עברית',
  hi: 'हिंदी',
  hmn: 'Hmong',
  hu: 'Magyar',
  is: 'Íslenska',
  ig: 'Ásụ̀sụ̀ Ìgbò',
  id: 'bahasa Indonesia',
  ga: 'Gaeilge',
  it: 'Italiano',
  ja: '日本語',
  jv: 'basa Jawa',
  kn: 'ಕನ್ನಡ',
  kk: 'Қазақ',
  km: 'ភាសាខ្មែរ',
  ko: '한국어',
  ku: 'Kurdî',
  lo: 'ລາວ',
  lv: 'Latviešu valoda',
  lt: 'Lietuvis',
  lb: 'Lëtzebuergesch',
  mk: 'Македонски',
  mg: 'Malagasy',
  ms: 'Bahasa Melayu',
  ml: 'മലയാളം',
  mi: 'Maori',
  mr: 'मराठी',
  mn: 'Монгол',
  my: 'မြန်မာ',
  ne: 'नेपाली',
  no: 'Norsk',
  ps: 'پښتو',
  fa: 'فارسی',
  pl: 'Polski',
  pt: 'Portugues',
  pa: 'ਪੰਜਾਬੀ',
  ro: 'Română',
  ru: 'русский',
  sm: 'Gagana Sāmoa',
  gd: 'Am Faclair Beag',
  sr: 'Српски',
  st: 'Sesotho',
  sn: 'Shona',
  sd: 'سنڌي',
  si: 'සිංහල',
  sk: 'Slovenský',
  sl: 'Slovenski',
  so: 'Af-Soomaali',
  es: 'Español',
  sw: 'Kiswahili',
  sv: 'Svenska',
  tl: 'Tagalog (Filipino)',
  tg: 'забо́ни тоҷикӣ́',
  ta: 'தமிழ்',
  te: 'తెలుగు',
  th: 'ภาษาไทย',
  tr: 'Türkçe',
  uk: 'Українська',
  ur: 'اردو',
  uz: "O'zbek",
  vi: 'Tiếng Việt',
  cy: 'Cymraeg',
  xh: 'isiXhosa',
  yi: 'יידיש',
  yo: 'Èdè Yorùbá',
  zu: 'IsiZulu',
};

export const interfaceLanguageToGoogleLanguageCode = {
  'zh-Hans': 'zh-CN',
  'zh-Hant': 'zh-TW',
  ja: 'ja',
  fr: 'fr',
  uk: 'uk',
  de: 'de',
  ru: 'ru',
  pt: 'pt',
  es: 'es',
  en: null,
};
