import { v4 as uuidv4 } from 'uuid';
import * as actions from '../actions';

const initialState = {
  settingsModalVisible: false,
  interfaceLanguage: 'en',
  translationLanguage: null,
  highlightIssues: true,
  modalProps: {
    showCloseButton: true,
    showNextButton: false,
  },
  customerId: null,
  subscription: null,
  settingsLoaded: false,
  documentId: uuidv4(),
};

let settings = (
  state = initialState,
  {
    type,
    field,
    value,
    allSettings,
    modalProps,
    error,
    dailyWordsRemaining,
    editorChangedAt,
    checkGrammarRequested,
    errorFetchingCorrections,
    showCorrectionsLoader,
  }
) => {
  switch (type) {
    case actions.SHOW_SETTINGS_MODAL:
      return {
        ...state,
        settingsModalVisible: true,
        modalProps: {
          showCloseButton: true,
          showNextButton: false,
          ...modalProps,
        },
      };
    case actions.HIDE_SETTINGS_MODAL:
      return {
        ...state,
        settingsModalVisible: false,
      };
    case actions.SHOW_ACCOUNT_MODAL:
      return {
        ...state,
        accountModalVisible: true,
        modalProps: {
          showCloseButton: true,
          showNextButton: false,
          ...modalProps,
        },
      };
    case actions.HIDE_ACCOUNT_MODAL:
      return {
        ...state,
        accountModalVisible: false,
      };
    case actions.SUBSCRIPTION_CANCEL_PENDING:
      return {
        ...state,
        subscriptionCancelPending: true,
      };
    case actions.SUBSCRIPTION_CANCEL_COMPLETE:
      return {
        ...state,
        subscriptionCancelPending: false,
        subscriptionError: error,
      };
    case actions.SET_DAILY_WORDS_REMAINING:
      return {
        ...state,
        dailyWordsRemaining,
      };
    case actions.SET_SETTING_VALUE:
      return {
        ...state,
        [field]: value,
      };
    case actions.SET_EDITOR_CHANGED_AT:
      return {
        ...state,
        editorChangedAt,
      };
    case actions.SET_CHECK_GRAMMAR_REQUESTED:
      return {
        ...state,
        checkGrammarRequested,
      };
    case actions.SET_ERROR_FETCHING_CORRECTIONS:
      return {
        ...state,
        errorFetchingCorrections,
      };
    case actions.SET_SHOW_CORRECTIONS_LOADER:
      return {
        ...state,
        showCorrectionsLoader,
      };
    case actions.LOAD_SETTINGS:
      return {
        ...state,
        ...allSettings,
        settingsLoaded: true,
      };
    default:
      return state;
  }
};

export default settings;
