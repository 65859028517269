import React from 'react';
import { forkJoin, from, of, Subject, timer } from 'rxjs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  concatMap,
  delayWhen,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  toArray,
} from 'rxjs/operators';
import Editor from './Editor';
import {
  addSentence,
  clearCache,
  fetchSettings,
  removeSentence,
  sendWritingActivity,
  setDailyWordsRemaining,
  setErrorFetchingCorrections,
  setProficiency,
  setShowCorrectionsLoader,
  updateAllSentences,
  updateSentence,
} from './actions';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { diffWords } from 'diff';
import FullScreenLoader from './FullScreenLoader';
import CrispChat from './CrispChat';
import api from './Api';
import { v4 as uuidv4 } from 'uuid';

const SENTENCES_API_DELAY = 1500;
const CHANGE_DEBOUNCE_DELAY = 1500;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#334CFF',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

class App extends React.Component {
  gramara$ = new Subject();
  lastAdditionCount = 0;

  componentDidMount() {
    this.props.fetchSettings();

    this.gramara$
      .pipe(
        distinctUntilChanged(),
        switchMap((text) => {
          return of(text).pipe(
            delayWhen(({ text, apply }) => {
              if (text && text.text && text.text.length && !apply) {
                this.props.setShowCorrectionsLoader(true);
                return timer(200);
              }
              return timer(0);
            }),
            delayWhen(({ text, apply }) => (!apply ? timer(CHANGE_DEBOUNCE_DELAY - 200) : timer(0))),
            tap(this.props.updateAllSentences)
          );
        }),
        switchMap(this.processSentences)
      )
      .subscribe();
  }

  checkCache = (results, text, segments) => {
    if (!results) {
      return [];
    }
    const updatedSegment = segments ? segments.find((s) => s.changingTo) : null;

    let tempArr = [];

    if (!this.props.cache.length) {
      results.forEach(([sentence, position, lineId, lineIndex, sentenceIndex]) => {
        if (!sentence) return null;

        const sentenceId =
          updatedSegment && updatedSegment.changingTo === sentence ? updatedSegment.sentenceId : uuidv4();

        let newSentence = {
          sentence,
          position,
          origin: text.slice(position[0], position[1]),
          mistakes: 'unknown',
          correction: 'fetching',
          isUsed: true,
          sentenceId,
          lineId,
          sentenceIndex,
        };

        tempArr.push({
          sentence,
          sentenceId: newSentence.sentenceId,
          lineId,
          lineIndex,
          sentenceIndex,
        });

        this.props.addSentence(newSentence);
      });
    } else {
      const writingStyle = this.props.writingStyle;

      results.forEach((item) => {
        let [sentence, position, lineId, lineIndex, sentenceIndex] = item;

        let hasInCache = this.props.cache.find(
          (cache) => cache.sentence === sentence && cache.writingStyle === writingStyle
        );
        let hasInCacheIndex = this.props.cache.findIndex((cache) => cache.sentence === sentence);

        if (!hasInCache) {
          const sentenceId =
            updatedSegment && updatedSegment.changingTo === sentence ? updatedSegment.sentenceId : uuidv4();
          let newSentence = {
            sentence,
            position,
            origin: text.slice(position[0], position[1]),
            mistakes: 'unknown',
            correction: 'fetching',
            isUsed: true,
            sentenceId,
            lineId,
            sentenceIndex,
          };

          tempArr.push({
            sentence,
            sentenceId: newSentence.sentenceId,
            lineId,
            lineIndex,
            sentenceIndex,
          });

          this.props.addSentence(newSentence);
        } else {
          this.props.updateSentence(hasInCacheIndex, { isUsed: true });
        }
      });
    }

    return tempArr;
  };

  segmentEndpoint = ({ lineIndex, lineId, text }) => {
    if (text.trim() === '') {
      return from([]);
    }
    const { userId, documentId } = this.props;
    const theLineId = lineId ? lineId : uuidv4();
    return from(
      api.post(
        '/segment',
        `user_id=${userId}` +
          `&document_id=${documentId}` +
          `&line_id=${theLineId}` +
          `&line_index=${lineIndex}` +
          `&input=${encodeURIComponent(text)}`,
        {
          timeout: 5000,
        }
      )
    ).pipe(
      map(({ data: { results } }) => {
        try {
          return results.map((r, sentenceIndex) => [...r, theLineId, lineIndex, sentenceIndex]);
        } catch (e) {
          return [];
        }
      })
    );
  };

  highlightEndpoint = ({ lineIndex, lineId, sentenceId, sentence, sentenceIndex }) => {
    const { userId, documentId } = this.props;
    return from(
      api.post(
        '/highlight',
        `user_id=${userId}` +
          `&document_id=${documentId}` +
          `&line_id=${lineId}` +
          `&line_index=${lineIndex}` +
          `&sentence_id=${sentenceId}` +
          `&sentence_index=${sentenceIndex}` +
          `&input=${encodeURIComponent(sentence)}`,
        { timeout: 10000 }
      )
    ).pipe(
      map(({ data: { score, spans } }) => {
        if (score === undefined || spans === undefined) {
          return [];
        }
        const scoringFn = ([start, end, spanScore]) => [
          start,
          end,
          Math.min(1, 0.2 * score + 0.3 * spanScore + score * spanScore),
        ];
        return spans.map(scoringFn);
      })
    );
  };

  correctionEndpoint = ({ lineIndex, lineId, sentenceId, sentence, sentenceIndex }) => {
    const { userId, documentId, writingStyle, proficiency } = this.props;

    return from(
      api.post(
        '/correction',
        `user_id=${userId}` +
          `&document_id=${documentId}` +
          `&line_id=${lineId}` +
          `&line_index=${lineIndex}` +
          `&sentence_id=${sentenceId}` +
          `&sentence_index=${sentenceIndex}` +
          `&input=${encodeURIComponent(sentence)}` +
          `&writing_style=${writingStyle}` +
          `&proficiency=${proficiency}`,
        { timeout: 10000 }
      )
    ).pipe(map(({ data: { results } }) => results));
  };

  processSentences = ({ text: pure }) => {
    if (!pure) {
      this.props.clearCache();
      return of(null);
    }

    const prevSentences = this.props.cache
      .map((c) => c.sentence)
      .filter((x) => x && x.text)
      .join(' ');
    const nextSentences = pure.filter((x) => x && x.text).join(' ');

    const additions = diffWords(prevSentences, nextSentences)
      .filter((diff) => diff.added)
      .map((diff) => diff.value.trim())
      .reduce((acc, sentence) => {
        return acc + sentence.split(' ').length;
      }, 0);

    this.lastAdditionCount += additions;

    return from(pure).pipe(
      filter((x) => x && x.text),
      mergeMap(({ lineIndex, lineId, text, segments }, index) => {
        return this.segmentEndpoint({ lineIndex, lineId, text }).pipe(
          delayWhen(() => (index !== 0 && index % 5 === 0 ? timer(SENTENCES_API_DELAY) : timer(0))),
          map((res) => this.checkCache(res, text, segments)),
          concatMap(this.processMistakes)
        );
      }, 5),
      toArray()
    );
  };

  processMistakes = (sentencesInput) => {
    const sentences = sentencesInput.filter(({ sentence }) => sentence.trim());
    this.props.setShowCorrectionsLoader(false);

    if (this.props.dailyWordsRemaining <= 0) {
      return from(sentences).pipe(
        concatMap(({ lineIndex, lineId, sentenceId, sentence, sentenceIndex }) =>
          forkJoin(this.highlightEndpoint({ lineIndex, lineId, sentenceId, sentence, sentenceIndex })).pipe(
            map(([mistakes]) => ({
              mistakes,
              sentence,
            }))
          )
        ),
        map(({ mistakes, sentence }) => {
          let index = this.props.cache.findIndex((cache) => cache.sentence === sentence);

          this.props.updateSentence(index, {
            mistakes: mistakes && mistakes.length ? mistakes : 'clear',
          });

          return null;
        })
      );
    } else {
      return from(sentences).pipe(
        concatMap(({ lineIndex, lineId, sentenceId, sentence, sentenceIndex }) =>
          forkJoin(
            this.highlightEndpoint({ lineIndex, lineId, sentenceId, sentence, sentenceIndex }),
            this.correctionEndpoint({ lineIndex, lineId, sentenceId, sentence, sentenceIndex })
          ).pipe(
            map(([mistakes, correction]) => ({
              mistakes,
              correction,
              sentence,
            }))
          )
        ),
        map(({ mistakes, correction, sentence }) => {
          if (!correction) {
            this.props.setErrorFetchingCorrections(true);
            let index = this.props.cache.findIndex((cache) => cache.sentence === sentence);
            if (index > -1) {
              this.props.removeSentence(index);
            }
            return null;
          }

          if (this.props.errorFetchingCorrections) {
            this.props.setErrorFetchingCorrections(false);
          }

          if (correction.length && this.lastAdditionCount) {
            this.props.sendWritingActivity(this.lastAdditionCount, () => {
              this.lastAdditionCount = 0;
            });
          }

          let index = this.props.cache.findIndex((cache) => cache.sentence === sentence);
          let same = correction.findIndex((c) => c.trim() === sentence.trim());

          if (same !== -1) {
            correction.splice(same, 1);
          }

          this.props.updateSentence(index, {
            correction: correction,
            mistakes: mistakes && mistakes.length ? mistakes : 'clear',
          });

          return null;
        })
      );
    }
  };

  render() {
    if (!this.props.settingsLoaded) {
      return <FullScreenLoader />;
    }

    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Editor startSegmentation={this.gramara$} />
        </div>
        <CrispChat />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cache: state.sentences,
    writingStyle: state.settings.writingStyle,
    proficiency: state.settings.proficiency,
    dailyWordsRemaining: state.settings.dailyWordsRemaining,
    subscription: state.settings.subscription,
    settingsLoaded: state.settings.settingsLoaded,
    errorFetchingCorrections: state.settings.errorFetchingCorrections,
    documentId: state.settings.documentId,
    userId: state.user.uid,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addSentence: bindActionCreators(addSentence, dispatch),
  updateSentence: bindActionCreators(updateSentence, dispatch),
  removeSentence: bindActionCreators(removeSentence, dispatch),
  clearCache: bindActionCreators(clearCache, dispatch),
  updateAllSentences: bindActionCreators(updateAllSentences, dispatch),
  setProficiency: bindActionCreators(setProficiency, dispatch),
  fetchSettings: bindActionCreators(fetchSettings, dispatch),
  setDailyWordsRemaining: bindActionCreators(setDailyWordsRemaining, dispatch),
  sendWritingActivity: bindActionCreators(sendWritingActivity, dispatch),
  setErrorFetchingCorrections: bindActionCreators(setErrorFetchingCorrections, dispatch),
  setShowCorrectionsLoader: bindActionCreators(setShowCorrectionsLoader, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
