import axios from 'axios';

class API {
  constructor() {
    this.axios = axios.create({
      baseURL: process.env.GRAMARA_API_URL,
    });
  }

  post = async (url, data, config = {}) => {
    let retries = 3;

    while (retries > 0) {
      try {
        const resp = await this.axios.post(url, data, config);
        return new Promise((resolve) => resolve(resp));
      } catch (err) {
        console.error(err);
        console.error('Retrying...');
        retries -= 1;
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }

    console.log('Ran out of retries');
    return new Promise((resolve) => resolve({ data: {} }));
  };
}

const apiClient = new API();

export default apiClient;
