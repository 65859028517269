import React, { useState } from 'react';
import MaterialSwitch from '@material-ui/core/Switch';
import MaterialGrid from '@material-ui/core/Grid';
import MaterialFormGroup from '@material-ui/core/FormGroup';
import MaterialButton from '@material-ui/core/Button';
import MaterialMenu from '@material-ui/core/Menu';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RightChevronBlue from '../icons/rightchevronblue.svg';
import withStyles from '@material-ui/core/styles/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingValue } from '../actions';
import { useTranslation } from 'react-i18next';
import { translationOptions, codeToLanguage, interfaceLanguageToGoogleLanguageCode } from './GoogleLanguages';

const BigSwitch = withStyles((theme) => ({
  root: {
    width: 54,
    height: 28,
    padding: 0,
    margin: theme.spacing(1),
    marginTop: 2,
  },
  switchBase: {
    padding: 1,
    transform: 'translate(2px, 2px)',
    '&$checked': {
      transform: 'translate(28px, 1px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#334CFF',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    height: 26,
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    backgroundColor: '#E6EBFF',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <MaterialSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const BigSwitchLabel = ({ primary, secondary }) => (
  <div className="switch-label">
    <p style={{ marginBottom: '6px', color: 'black' }}>{primary}</p>
    <p>{secondary}</p>
  </div>
);

const prettifyLanguage = (languageRaw) => {
  switch (languageRaw) {
    case 'en':
      return 'English';
    case 'zh-Hans':
      return '中文 - 简体';
    case 'zh-Hant':
      return '中文 - 繁體';
    case 'ja':
      return '日本語';
    case 'fr':
      return 'Français';
    case 'uk':
      return 'Українська';
    case 'de':
      return 'Deutsch';
    case 'ru':
      return 'русский';
    case 'pt':
      return 'Portugues';
    case 'es':
      return 'Español';
    default:
      return 'English';
  }
};

export default () => {
  const [interfaceLanguageAnchorEl, setInterfaceLanguageAnchorEl] = useState(null);
  const [translationLanguageAnchorEl, setTranslationLanguageAnchorEl] = useState(null);
  const interfaceLanguage = useSelector((state) => state.settings.interfaceLanguage);
  const translationLanguage = useSelector((state) => state.settings.translationLanguage);
  const highlightIssues = useSelector((state) => state.settings.highlightIssues);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const openInterfaceLanguageMenu = (event) => {
    setInterfaceLanguageAnchorEl(event.currentTarget);
  };

  const openTranslationLanguageMenu = (event) => {
    setTranslationLanguageAnchorEl(event.currentTarget);
  };

  const selectLanguage = (languageType, language) => {
    dispatch(setSettingValue(languageType, language));
    if (languageType === 'interfaceLanguage') {
      dispatch(setSettingValue('translationLanguage', interfaceLanguageToGoogleLanguageCode[language]));
    }

    closeLanguageMenu();
  };

  const closeLanguageMenu = () => {
    setInterfaceLanguageAnchorEl(null);
    setTranslationLanguageAnchorEl(null);
  };

  return (
    <div className="choices">
      <MaterialFormGroup>
        <MaterialGrid
          container
          className="settings-grid"
          direction="row"
          justify="space-between"
          alignItems="flex-end"
          spacing={2}
        >
          <MaterialGrid container item xs={12} sm={12} md={12} spacing={2}>
            <MaterialGrid item>
              <FormControlLabel
                className="switch-control"
                control={
                  <BigSwitch
                    className="big-switch"
                    checked={highlightIssues}
                    onChange={(event) => {
                      dispatch(setSettingValue('highlightIssues', event.target.checked));
                    }}
                  />
                }
                label={
                  <BigSwitchLabel
                    primary={t('common:highlightIssuesSetting')}
                    secondary={t('common:highlightIssuesSettingDescription')}
                  />
                }
              />
            </MaterialGrid>
          </MaterialGrid>
          <MaterialGrid container item xs={12}>
            <div className="divider" />
          </MaterialGrid>

          <MaterialGrid item className="language-grid-item">
            <div className="language-settings">
              <p style={{ color: 'black' }}>{t('common:interfaceLanguageSetting')}</p>
              <p>{t('common:interfaceLanguageSettingDescription')}</p>
            </div>
            <div className="language-settings">
              <MaterialButton
                className="language-menu-btn"
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={openInterfaceLanguageMenu}
              >
                <img src={RightChevronBlue} alt="" />
                {prettifyLanguage(interfaceLanguage)}
              </MaterialButton>
            </div>

            <div className="language-settings" style={{ marginTop: 32 }}>
              <p style={{ color: 'black' }}>{t('common:backtranslationLanguageSetting')}</p>
              <p>{t('common:backtranslationLanguageSettingDescription')}</p>
            </div>
            <div className="language-settings">
              <MaterialButton
                data-lang-type="translationLanguage"
                className="language-menu-btn"
                aria-controls="translate-language-menu"
                aria-haspopup="true"
                onClick={openTranslationLanguageMenu}
              >
                <img src={RightChevronBlue} alt="" />
                {translationLanguage ? prettifyLanguage(translationLanguage) : 'None'}
              </MaterialButton>
            </div>
          </MaterialGrid>
        </MaterialGrid>

        <MaterialMenu
          id="language-menu"
          anchorEl={interfaceLanguageAnchorEl}
          keepMounted
          open={Boolean(interfaceLanguageAnchorEl)}
          onClose={closeLanguageMenu}
        >
          {['en', 'fr', 'uk', 'de', 'ru', 'es', 'pt', 'zh-Hans', 'zh-Hant', 'ja'].map((lang) => (
            <MaterialMenuItem onClick={() => selectLanguage('interfaceLanguage', lang)}>
              {prettifyLanguage(lang)}
            </MaterialMenuItem>
          ))}
        </MaterialMenu>

        <MaterialMenu
          id="translation-language-menu"
          anchorEl={translationLanguageAnchorEl}
          keepMounted
          open={Boolean(translationLanguageAnchorEl)}
          onClose={closeLanguageMenu}
        >
          <MaterialMenuItem onClick={() => selectLanguage('translationLanguage', null)}>None</MaterialMenuItem>
          {['fr', 'uk', 'de', 'ru', 'es', 'pt', 'zh-Hans', 'zh-Hant', 'ja'].map((lang) => (
            <MaterialMenuItem onClick={() => selectLanguage('translationLanguage', lang)}>
              {prettifyLanguage(lang)}
            </MaterialMenuItem>
          ))}
        </MaterialMenu>
      </MaterialFormGroup>
    </div>
  );
};
