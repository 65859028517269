import React, { useState } from 'react';

const nonBreakingSpace = '\u00A0';

function CorrectionRemovedWord({ removeWord, word, hover, zIndex }) {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    if (open) {
      removeWord();
    } else {
      setOpen(true);
    }
  };

  const hoverProps = {
    onMouseEnter: () => setOpen(true),
    onMouseLeave: () => setOpen(false),
  };
  const otherProps = {
    onClick: handleClick,
    className: `correction-removed-word ${open ? 'open' : ''}`
  }
  const props = hover ? {...hoverProps, ...otherProps} : otherProps;

  return (
    <span
      style={{zIndex}}
      className="correction-removed-marker"
    >
      <span {...props}>
          {open ? word : nonBreakingSpace}
      </span>
    </span>
  );
}

export default CorrectionRemovedWord;
